import {
  Link,
  SxProps,
  useTheme,
  Theme,
  Typography,
  styled,
} from "@mui/material";
import { useAgency } from "../../hooks/use-agency";
import { getContrastColor } from "../../utils";

export type LogoProps = {
  sx?: SxProps<Theme>;
};

export default function Logo({ sx }: LogoProps) {
  const agency = useAgency();
  const theme = useTheme();

  return (
    <AgencyLink href="/" sx={sx} underline="none">
      {!agency?.logotype_document_id && (
        <Typography
          fontWeight="700"
          fontSize={22}
          color={getContrastColor(theme.palette.primary.main)}
        >
          {agency?.name}
        </Typography>
      )}
      {agency?.logotype_document_id && (
        <Image
          alt={agency.name}
          src={`/api/documents/${agency.logotype_document_id}?format=webp`}
        />
      )}
    </AgencyLink>
  );
}

const AgencyLink = styled(Link)({
  display: "inline-block",
});

const Image = styled("img")(({ theme }) => ({
  display: "inline-block",
  width: "auto",
  height: "auto",
  maxWidth: 240,
  maxHeight: 40,
  marginTop: 7,
  [theme.breakpoints.up("md")]: {
    maxWidth: 200,
    maxHeight: 58,
  },
}));
