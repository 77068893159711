var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Transform } from "class-transformer";
import { IsBoolean, IsOptional } from "class-validator";
export class FindAllChannelFiltersDto {
    with_integration_config;
    with_acquisition;
}
__decorate([
    IsOptional(),
    IsBoolean(),
    Transform(({ value }) => value === "true"),
    __metadata("design:type", Boolean)
], FindAllChannelFiltersDto.prototype, "with_integration_config", void 0);
__decorate([
    IsOptional(),
    IsBoolean(),
    Transform(({ value }) => value === "true"),
    __metadata("design:type", Boolean)
], FindAllChannelFiltersDto.prototype, "with_acquisition", void 0);
export class CreateAuditChannelRequestDto {
    channel_code;
    request_url;
    request_method;
    request_headers;
    request_body;
}
export class UpdateResponseAuditChannelRequestDto {
    response_status;
    response_headers;
    response_body;
}
