"use client";

import { Box, CssBaseline, ThemeProvider } from "@mui/material";
import React, { useMemo } from "react";
import { createAgencyTheme } from "../components/agency-theme";
import { AgencyWebsiteProfile } from "@serviestate/shared";
import { AgencyProvider } from "./agency-provider";

export default function Providers({
  agency,
  children,
}: React.PropsWithChildren<{ agency: AgencyWebsiteProfile }>) {
  const agencyTheme = useMemo(() => createAgencyTheme(agency), [agency]);
  return (
    <ThemeProvider theme={agencyTheme}>
      <Box
        sx={{ "&&": { "--color-primary": agencyTheme.palette.primary.main } }}
      >
        <CssBaseline />
        <AgencyProvider agency={agency}>{children}</AgencyProvider>
      </Box>
    </ThemeProvider>
  );
}
