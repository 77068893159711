var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Type } from "class-transformer";
import { IsArray, IsBoolean, IsDate, IsEmail, IsNotEmpty, IsNumber, IsOptional, IsString, IsUUID, Length, } from "class-validator";
export class ContactsStatisticsDto {
    from_date;
    to_date;
}
__decorate([
    IsDate(),
    Type(() => Date),
    __metadata("design:type", Date)
], ContactsStatisticsDto.prototype, "from_date", void 0);
__decorate([
    IsDate(),
    Type(() => Date),
    __metadata("design:type", Date)
], ContactsStatisticsDto.prototype, "to_date", void 0);
export class ContactsStatisticsItem {
    date;
    new_contacts;
}
export class CreateContactDto {
    nif;
    firstname;
    surname;
    second_surname;
    alias;
    email;
    mobile;
    language_code;
    captor_by;
    mean_id;
    geo_address_id;
    tags;
    observations;
    important_warn;
    is_business;
}
__decorate([
    IsOptional(),
    IsString(),
    __metadata("design:type", String)
], CreateContactDto.prototype, "nif", void 0);
__decorate([
    IsNotEmpty(),
    IsString(),
    __metadata("design:type", String)
], CreateContactDto.prototype, "firstname", void 0);
__decorate([
    IsOptional(),
    IsString(),
    __metadata("design:type", String)
], CreateContactDto.prototype, "surname", void 0);
__decorate([
    IsOptional(),
    IsString(),
    __metadata("design:type", String)
], CreateContactDto.prototype, "second_surname", void 0);
__decorate([
    IsOptional(),
    IsString(),
    __metadata("design:type", String)
], CreateContactDto.prototype, "alias", void 0);
__decorate([
    IsOptional(),
    IsString(),
    IsEmail(),
    __metadata("design:type", String)
], CreateContactDto.prototype, "email", void 0);
__decorate([
    IsOptional(),
    IsString(),
    __metadata("design:type", String)
], CreateContactDto.prototype, "mobile", void 0);
__decorate([
    IsOptional(),
    IsString(),
    Length(2),
    __metadata("design:type", String)
], CreateContactDto.prototype, "language_code", void 0);
__decorate([
    IsOptional(),
    IsUUID(),
    __metadata("design:type", String)
], CreateContactDto.prototype, "captor_by", void 0);
__decorate([
    IsOptional(),
    IsUUID(),
    __metadata("design:type", String)
], CreateContactDto.prototype, "mean_id", void 0);
__decorate([
    IsOptional(),
    IsNumber(),
    __metadata("design:type", Number)
], CreateContactDto.prototype, "geo_address_id", void 0);
__decorate([
    IsOptional(),
    IsArray(),
    IsString({ each: true }),
    __metadata("design:type", Array)
], CreateContactDto.prototype, "tags", void 0);
__decorate([
    IsOptional(),
    IsString(),
    __metadata("design:type", String)
], CreateContactDto.prototype, "observations", void 0);
__decorate([
    IsOptional(),
    IsString(),
    __metadata("design:type", String)
], CreateContactDto.prototype, "important_warn", void 0);
__decorate([
    IsOptional(),
    IsBoolean(),
    __metadata("design:type", Boolean)
], CreateContactDto.prototype, "is_business", void 0);
export class CreateContactRequestDto {
    fullname;
    email;
    mobile;
    language_code;
    message;
    mean_id;
    property_id;
}
export class UpdateContactDto extends CreateContactDto {
}
export class PatchContactDto {
    nif;
    firstname;
    surname;
    second_surname;
    email;
    mobile;
    is_business;
}
__decorate([
    IsOptional(),
    IsString(),
    __metadata("design:type", String)
], PatchContactDto.prototype, "nif", void 0);
__decorate([
    IsOptional(),
    IsString(),
    __metadata("design:type", String)
], PatchContactDto.prototype, "firstname", void 0);
__decorate([
    IsOptional(),
    IsString(),
    __metadata("design:type", String)
], PatchContactDto.prototype, "surname", void 0);
__decorate([
    IsOptional(),
    IsString(),
    __metadata("design:type", String)
], PatchContactDto.prototype, "second_surname", void 0);
__decorate([
    IsOptional(),
    IsString(),
    IsEmail(),
    __metadata("design:type", String)
], PatchContactDto.prototype, "email", void 0);
__decorate([
    IsOptional(),
    IsString(),
    __metadata("design:type", String)
], PatchContactDto.prototype, "mobile", void 0);
__decorate([
    IsOptional(),
    IsBoolean(),
    __metadata("design:type", Boolean)
], PatchContactDto.prototype, "is_business", void 0);
