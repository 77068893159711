var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createRequest } from "./create-request";
import { handleRequestWithTimeout } from "./handle-request-with-timeout";
import { handleRequestWithoutTimeout } from "./handle-request-without-timeout";
export const fetchAdapter = function fetchAdapter(config) {
    return __awaiter(this, void 0, void 0, function* () {
        const request = createRequest(config);
        if (config.timeout && config.timeout > 0) {
            return handleRequestWithTimeout(request, config);
        }
        else {
            return handleRequestWithoutTimeout(request, config);
        }
    });
};
