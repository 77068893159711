import { parseException } from "../errors";
export class AgentsController {
    constructor(axios) {
        this.axios = axios;
    }
    async findAll() {
        try {
            const response = await this.axios.get("/agencies/agents");
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async count() {
        try {
            const response = await this.axios.get("/agencies/agents/count");
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async detail(agentId) {
        try {
            const response = await this.axios.get(`/agencies/agents/${agentId}`);
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async update(agentId, dto) {
        try {
            const response = await this.axios.put(`/agencies/agents/${agentId}`, dto);
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async create(dto) {
        try {
            const response = await this.axios.post(`/agencies/agents`, dto);
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async unsubscribe(agentId) {
        try {
            const response = await this.axios.post(`/agencies/agents/${agentId}/unsubscribe`);
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async restore(agentId) {
        try {
            const response = await this.axios.post(`/agencies/agents/${agentId}/restore`);
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async invite(dto) {
        try {
            const response = await this.axios.post(`/agencies/agents/invite`, dto);
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async getPendingInvitations() {
        try {
            const response = await this.axios.get(`/agencies/agents/pending-invitations`);
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async cancelInvitation(invitationId) {
        try {
            const response = await this.axios.post(`/agencies/agents/cancel-invitation?invitation_id=${invitationId}`);
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async getInvitationInfo(agencyId, invitationCode) {
        try {
            const response = await this.axios.get(`/agencies/agents/invitation?agency_id=${agencyId}&code=${invitationCode}`);
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async acceptInvitation(agencyId, invitationCode) {
        try {
            const response = await this.axios.post(`/agencies/agents/accept-invitation?agency_id=${agencyId}&code=${invitationCode}`);
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async rejectInvitation(agencyId, invitationCode) {
        try {
            const response = await this.axios.post(`/agencies/agents/reject-invitation?agency_id=${agencyId}&code=${invitationCode}`);
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
}
