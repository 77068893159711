"use client";

import {
  Box,
  Button,
  Container,
  Divider,
  Hidden,
  IconButton,
  Link,
  List,
  Menu,
  MenuItem,
  SwipeableDrawer,
  styled,
  useTheme,
} from "@mui/material";
import Logo from "../logo";
import {
  AiOutlineMenu,
  AiOutlineClose,
  AiOutlineCaretDown,
} from "react-icons/ai";
import { useState } from "react";
import MenuItemMobile from "./menu-item-mobile";
import { useMenuLinks } from "../../hooks/use-menu-links";
import { useAgency } from "../../hooks/use-agency";
import { useTranslation } from "next-i18next";
import { getContrastColor } from "../../utils";
import { MdLanguage } from "react-icons/md";

export default function Header() {
  const { i18n } = useTranslation();
  const agency = useAgency();
  const theme = useTheme();
  const headerFontColor =
    agency?.logotype_document_id != null
      ? "#111"
      : getContrastColor(theme.palette.primary.main);
  const [showMenu, setShowMenu] = useState(false);
  const links = useMenuLinks();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const language = agency?.languages.find((x) => x.code === i18n.language) ?? {
    code: i18n.language,
    name: i18n.language,
  };

  const handleOpenLanguages = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseLanguages = () => {
    setAnchorEl(null);
    setShowMenu(false);
  };

  return (
    <Root hasLogo={!!agency?.logotype_document_id}>
      <Container maxWidth="xl">
        <Content>
          <Hidden mdUp>
            <IconButton
              size="large"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={() => setShowMenu(true)}
            >
              <AiOutlineMenu color={headerFontColor} />
            </IconButton>
          </Hidden>
          <Logo sx={{ flexGrow: 1 }} />
          <Hidden mdDown>
            <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
              {links.map((x) => (
                <Button
                  key={`m1_${x.name}`}
                  sx={{ fontSize: 18, color: headerFontColor }}
                  href={x.link}
                >
                  {x.name}
                </Button>
              ))}
              {agency?.languages.length > 1 && (
                <Button
                  sx={{
                    display: "inline-flex",
                    fontSize: 18,
                    gap: 1,
                    color: headerFontColor,
                  }}
                  onClick={handleOpenLanguages}
                >
                  <MdLanguage size={25} color={headerFontColor} />{" "}
                  <AiOutlineCaretDown size={20} color={headerFontColor} />
                </Button>
              )}
            </Box>
          </Hidden>
        </Content>
      </Container>
      <SwipeableDrawer
        open={showMenu}
        onOpen={() => setShowMenu(true)}
        onClose={() => setShowMenu(false)}
        PaperProps={{ sx: { maxWidth: "300px", width: "100%" } }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            p: 2,
          }}
        >
          <Logo />
          <IconButton onClick={() => setShowMenu(false)}>
            <AiOutlineClose />
          </IconButton>
        </Box>
        <Divider />
        <Box sx={{ flex: 1, overflowY: "auto", backgroundColor: "#f5f5f5" }}>
          <List>
            {links.map((x) => (
              <MenuItemMobile
                onClick={() => setShowMenu(false)}
                key={`m2_${x.name}`}
                href={x.link}
                label={x.name}
              />
            ))}
          </List>
        </Box>
        {agency?.languages.length > 1 && (
          <>
            <Divider />
            <Box textAlign="center">
              <Button
                sx={{
                  display: "inline-flex",
                  fontSize: 18,
                  gap: 1,
                  color: "inherit",
                }}
                onClick={handleOpenLanguages}
              >
                <MdLanguage size={25} /> {language.name} <AiOutlineCaretDown />
              </Button>
            </Box>
          </>
        )}
      </SwipeableDrawer>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseLanguages}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        MenuListProps={{ component: "div" } as any}
      >
        {agency?.languages.map((x) => (
          <MenuItem
            component={Link}
            key={x.code}
            selected={x.code === language.code}
            href={`/${x.code}`}
            sx={{ textDecoration: "none" }}
            onClick={handleCloseLanguages}
          >
            {x.name}
          </MenuItem>
        ))}
      </Menu>
    </Root>
  );
}

const Root = styled(Box, {
  shouldForwardProp: (p) => !["hasLogo"].includes(p as string),
})<{
  hasLogo: boolean;
}>(({ theme, hasLogo }) => ({
  position: "sticky",
  top: 0,
  backgroundColor: hasLogo
    ? theme.palette.background.paper
    : theme.palette.primary.main,
  color: hasLogo
    ? theme.palette.background.paper
    : getContrastColor(theme.palette.primary.main),
  width: "100%",
  boxShadow: theme.shadows[1],
  zIndex: 100,
}));

const Content = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1),
  [theme.breakpoints.up("md")]: {
    height: "80px",
  },
}));
