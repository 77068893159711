import { parseException } from "../errors";
export class DemandsController {
    constructor(axios) {
        this.axios = axios;
    }
    async find(filter) {
        try {
            const response = await this.axios.get("/demands", {
                params: filter,
            });
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async detail(id) {
        try {
            const response = await this.axios.get(`/demands/${id}`);
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async count(filter) {
        try {
            const response = await this.axios.get("/demands/count", {
                params: filter,
            });
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async create(data) {
        try {
            const response = await this.axios.post("/demands", data);
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async update(id, data) {
        try {
            const response = await this.axios.patch(`/demands/${id}`, data);
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async updateBatch(batch) {
        try {
            const response = await this.axios.patch(`/demands/batch`, batch);
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async unarchive(id) {
        try {
            const response = await this.axios.post(`/demands/${id}/unarchive`);
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async archive(id) {
        try {
            const response = await this.axios.delete(`/demands/${id}`);
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async forceCrossing(id) {
        try {
            await this.axios.post(`/demands/${id}/force-crossing`);
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async checkDemandJob(demandId) {
        try {
            const response = await this.axios.post(`/demands/${demandId}/check-demand-job`);
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async getStatistics(dto) {
        try {
            const response = await this.axios.post("/demands/statistics", dto);
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
}
