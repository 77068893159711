var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { IsBoolean, IsDate, IsNumber, IsOptional, IsString, IsUUID, } from "class-validator";
import { Type } from "class-transformer";
export class AgendaEventChangePeriodDto {
    start;
    end;
}
__decorate([
    IsDate(),
    Type(() => Date),
    __metadata("design:type", Date)
], AgendaEventChangePeriodDto.prototype, "start", void 0);
__decorate([
    IsDate(),
    Type(() => Date),
    __metadata("design:type", Date)
], AgendaEventChangePeriodDto.prototype, "end", void 0);
export class CalendarEvent {
    id;
    agency_id;
    user_id;
    user_status_code;
    name;
    start_at;
    end_at;
    geo_address_id;
    description;
    all_day;
    user;
    agent_participants;
    contact_participants;
}
export class CalendarEventAgent {
    calendar_event_id;
    user_id;
    user;
}
export class CalendarEventContact {
    calendar_event_id;
    contact_id;
    contact;
}
export class CalendarEventMutateDto {
    name;
    description;
    start_at;
    end_at;
    user_id;
    user_status_code;
    geo_address_id;
    agent_participants;
    contact_participants;
    all_day;
}
__decorate([
    IsString(),
    __metadata("design:type", String)
], CalendarEventMutateDto.prototype, "name", void 0);
__decorate([
    IsOptional(),
    IsString(),
    __metadata("design:type", String)
], CalendarEventMutateDto.prototype, "description", void 0);
__decorate([
    IsDate(),
    Type(() => Date),
    __metadata("design:type", Date)
], CalendarEventMutateDto.prototype, "start_at", void 0);
__decorate([
    IsDate(),
    Type(() => Date),
    __metadata("design:type", Date)
], CalendarEventMutateDto.prototype, "end_at", void 0);
__decorate([
    IsUUID(),
    __metadata("design:type", String)
], CalendarEventMutateDto.prototype, "user_id", void 0);
__decorate([
    IsString(),
    __metadata("design:type", String)
], CalendarEventMutateDto.prototype, "user_status_code", void 0);
__decorate([
    IsOptional(),
    IsNumber(),
    __metadata("design:type", Number)
], CalendarEventMutateDto.prototype, "geo_address_id", void 0);
__decorate([
    IsOptional(),
    IsString({ each: true }),
    __metadata("design:type", Array)
], CalendarEventMutateDto.prototype, "agent_participants", void 0);
__decorate([
    IsOptional(),
    IsString({ each: true }),
    __metadata("design:type", Array)
], CalendarEventMutateDto.prototype, "contact_participants", void 0);
__decorate([
    IsBoolean(),
    __metadata("design:type", Boolean)
], CalendarEventMutateDto.prototype, "all_day", void 0);
