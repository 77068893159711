import buildURL from "axios/unsafe/helpers/buildURL";
import { buildFullPath } from "./build-full-path";
import FormData from "form-data";
export function createRequest(config) {
    if (config.data instanceof FormData) {
        delete config.headers["Content-Type"];
    }
    const headers = new Headers(config.headers);
    // HTTP basic authentication
    if (config.auth) {
        const username = config.auth.username || "";
        const password = config.auth.password
            ? decodeURI(encodeURIComponent(config.auth.password))
            : "";
        headers.set("Authorization", `Basic ${Buffer.from(username + ":" + password).toString("base64")}`);
    }
    if (config.method === undefined) {
        config.method = "get";
    }
    const method = config.method.toUpperCase();
    const options = { headers, method };
    if (method !== "GET" && method !== "HEAD") {
        options.body = config.data;
    }
    // This config is similar to XHR’s withCredentials flag, but with three available values instead of two.
    // So if withCredentials is not set, default value 'same-origin' will be used
    if (config.withCredentials !== undefined) {
        options.credentials = config.withCredentials ? "include" : "omit";
    }
    const fullPath = buildFullPath(config.url ? config.url : "", config.baseURL);
    const serializer = config.paramsSerializer
        ? config.paramsSerializer
        : undefined;
    const url = buildURL(fullPath, config.params, serializer);
    return new Request(url, options);
}
