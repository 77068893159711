import { ServiestateApi } from "@serviestate/sdk";
import fetchAdapter from "@serviestate/axios-fetch-adapter";

const endpoint = process.env.API_INTERNAL_ENDPOINT ?? "http://dev-env:3100";

const sdk = new ServiestateApi({
  adapter: fetchAdapter,
  endpoint: endpoint + "/api/",
});

if (process.env.API_INTERNAL_SERVICE_TOKEN)
  sdk.setCredentials(process.env.API_INTERNAL_SERVICE_TOKEN, "Token");

export default sdk;
