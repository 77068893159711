import type { AgencyWebsiteProfile } from "@serviestate/shared";
import React, { createContext } from "react";

export const agencyContext = createContext<AgencyWebsiteProfile>(null as any);

export function AgencyProvider({
  agency,
  children,
}: React.PropsWithChildren<{ agency: AgencyWebsiteProfile }>) {
  return (
    <agencyContext.Provider value={agency}>{children}</agencyContext.Provider>
  );
}
