var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { IsOptional, IsUUID, IsBoolean, IsIn, IsObject, IsString, IsNotEmpty, ValidateIf, IsDate, } from "class-validator";
import { FindAllRequest } from "./find-all-request.js";
export class MessagesFindAllRequest extends FindAllRequest {
    contact_id;
}
__decorate([
    IsOptional(),
    IsUUID(),
    __metadata("design:type", String)
], MessagesFindAllRequest.prototype, "contact_id", void 0);
export class CreateMessageDto {
    body;
    body_type;
    incoming;
    contact_id;
    from;
    to;
    mean_id;
    metadata;
    subject;
    property_id;
    received_at;
    internet_message_id;
}
__decorate([
    IsString(),
    IsNotEmpty(),
    __metadata("design:type", String)
], CreateMessageDto.prototype, "body", void 0);
__decorate([
    IsString(),
    IsIn(["html", "text"]),
    __metadata("design:type", String)
], CreateMessageDto.prototype, "body_type", void 0);
__decorate([
    IsBoolean(),
    __metadata("design:type", Boolean)
], CreateMessageDto.prototype, "incoming", void 0);
__decorate([
    IsUUID(),
    __metadata("design:type", String)
], CreateMessageDto.prototype, "contact_id", void 0);
__decorate([
    ValidateIf((x) => x.incoming),
    IsString(),
    __metadata("design:type", String)
], CreateMessageDto.prototype, "from", void 0);
__decorate([
    ValidateIf((x) => !x.incoming),
    IsString(),
    __metadata("design:type", String)
], CreateMessageDto.prototype, "to", void 0);
__decorate([
    IsUUID(),
    __metadata("design:type", String)
], CreateMessageDto.prototype, "mean_id", void 0);
__decorate([
    IsOptional(),
    IsObject(),
    __metadata("design:type", Object)
], CreateMessageDto.prototype, "metadata", void 0);
__decorate([
    IsOptional(),
    IsString(),
    __metadata("design:type", String)
], CreateMessageDto.prototype, "subject", void 0);
__decorate([
    IsOptional(),
    IsUUID(),
    __metadata("design:type", String)
], CreateMessageDto.prototype, "property_id", void 0);
__decorate([
    IsOptional(),
    IsDate(),
    __metadata("design:type", Date)
], CreateMessageDto.prototype, "received_at", void 0);
__decorate([
    IsOptional(),
    IsString(),
    __metadata("design:type", String)
], CreateMessageDto.prototype, "internet_message_id", void 0);
