import { parseException } from "../errors";
export class AcquisitionsZoneController {
    constructor(axios) {
        this.axios = axios;
    }
    async count() {
        try {
            const response = await this.axios.get("/agency-acquisitions-zone/count");
            return response.data;
        }
        catch (e) {
            throw e;
        }
    }
    async find() {
        try {
            const response = await this.axios.get("/agency-acquisitions-zone");
            return response.data;
        }
        catch (e) {
            throw e;
        }
    }
    async detail(id) {
        try {
            const response = await this.axios.get(`/agency-acquisitions-zone/${id}`);
            return response.data;
        }
        catch (e) {
            throw e;
        }
    }
    async create(data) {
        try {
            const response = await this.axios.post("/agency-acquisitions-zone", data);
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async delete(id) {
        try {
            const response = await this.axios.patch(`/agency-acquisitions-zone/${id}`);
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
}
