var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createError } from "./create-error";
import { getErrorCodeFromStatus } from "./get-error-code-from-status";
export function processResponse(response, request, config) {
    return __awaiter(this, void 0, void 0, function* () {
        if (response instanceof Error) {
            return Promise.reject(response);
        }
        const validateStatus = config.validateStatus;
        if (!response.status || !validateStatus || validateStatus(response.status)) {
            return response;
        }
        const error = createError(`Request failed with status code ${response.status}`, config, getErrorCodeFromStatus(response.status), request, response);
        return Promise.reject(error);
    });
}
