export class PropertyTypesController {
    constructor(axios) {
        this.axios = axios;
    }
    async find() {
        try {
            const response = await this.axios.get("/property-types");
            return response.data;
        }
        catch (e) {
            throw e;
        }
    }
    async detail(id) {
        try {
            const response = await this.axios.get(`/property-types/${id}`);
            return response.data;
        }
        catch (e) {
            throw e;
        }
    }
}
