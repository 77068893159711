export class ServiestateApiError extends Error {
    code;
    constructor(code, message) {
        super(message);
        this.code = code;
    }
    static isServiestateApiError(error) {
        return error instanceof ServiestateApiError;
    }
}
