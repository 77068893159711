import { ServiestateApiError } from "@serviestate/shared/types";
import Axios from "axios";
export function parseException(error) {
    var _a;
    if (Axios.isAxiosError(error)) {
        if (error.response &&
            error.response.data &&
            "message" in error.response.data) {
            const msg = error.response.data;
            return new ServiestateApiError((_a = msg.code) !== null && _a !== void 0 ? _a : `error_${msg.statusCode}`, msg.message);
        }
        else {
            return new ServiestateApiError("AXIOS_ERROR", error.message);
        }
    }
    else {
        return new ServiestateApiError("UNKNOWN", error.toString());
    }
}
