import { useTranslation } from "next-i18next";
import { useAgency } from "./use-agency";
import slugify from "slugify";
import { getTranslation } from "../utils";

export function useMenuLinks() {
  const { t, i18n } = useTranslation();
  const agency = useAgency();

  if (!agency || !agency.menu || agency.menu.length === 0) return [];

  return agency.menu
    .sort((a, b) => a.position - b.position)
    .map((x) => {
      const name = getTranslation(i18n.language, x.label);
      if (!x.type || x.type === "link") {
        return { name, link: getTranslation(i18n.language, x.link) };
      } else if (x.type === "page") {
        const section = agency.sections.find((s) => s.id === x.page);
        if (!section) return null;
        if (section.id === "transactional") {
          const transactionType = agency.info.transactions.find(
            (c) => c.id === x.params?.transactionTypeId
          );

          if (!transactionType) return null;

          const term = t(`transaction.type_${transactionType.id}`);

          return {
            name,
            link: `/${i18n.language}/${slugify(term, {
              lower: true,
              trim: true,
              remove: /["']/g,
            })}`,
          };
        }
        const slug =
          typeof section.slug === "string"
            ? section.slug
            : section.slug[i18n.language] ?? section.slug.es;
        if (!slug) return null;
        return { name, link: `/${i18n.language}/${slug}` };
      } else {
      }
      return null;
    })
    .filter((x) => !!x) as { name: string; link: string }[];
}
