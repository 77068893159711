var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { parseException } from "../errors";
import UseRecaptcha from "../use-recaptcha";
export class AuthController {
    constructor(axios) {
        this.axios = axios;
    }
    async signIn(request) {
        try {
            const response = await this.axios.post("/auth/sign-in", request);
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async withToken(request) {
        try {
            const response = await this.axios.post("/auth/token", request);
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async refresh() {
        try {
            const response = await this.axios.post("/auth/refresh");
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async self() {
        try {
            const response = await this.axios.get("/auth/profile");
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async setAgency(agencyId) {
        try {
            const response = await this.axios.post("/auth/set-agency", { agency_id: agencyId });
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
}
__decorate([
    UseRecaptcha(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", Promise)
], AuthController.prototype, "signIn", null);
