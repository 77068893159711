import { createTheme } from "@mui/material";
import { Nunito } from "next/font/google";
import { AgencyProfile } from "@serviestate/shared";
import LinkBehavior from "./link-behavior";
import { LinkProps } from "@mui/material";

const nunito = Nunito({
  subsets: ["latin"],
  variable: "--default-font",
});

export const customScrollbar = {
  "::-webkit-scrollbar": {
    width: 4,
    height: 8,
    backgroundColor: "#cacaca",
  },
  "::-webkit-scrollbar-thumb": {
    backgroundColor: "#A8A8A9",
    borderRadius: 4,
  },
};

export function createAgencyTheme(agency: AgencyProfile) {
  return createTheme({
    palette: {
      primary: {
        main: agency?.palette?.primary ?? "#e11515",
      },
      secondary: {
        main: agency?.palette?.secondary ?? "#4d4d4d",
      },
      error: {
        main: agency?.palette?.error ?? "#e80000",
      },
      warning: {
        main: agency?.palette?.warning ?? "#e4c72b",
      },
      info: {
        main: agency?.palette?.info ?? "#64c5e4",
      },
      success: {
        main: agency?.palette?.success ?? "#88cc5f",
      },
      background: {
        default: "#f7f7f7",
      },
    },
    typography: {
      fontFamily: nunito.style.fontFamily,
      h1: {
        fontSize: 30,
      },
    },
    components: {
      MuiLink: {
        defaultProps: {
          component: LinkBehavior,
        } as LinkProps,
      },
      MuiButtonBase: {
        defaultProps: {
          LinkComponent: LinkBehavior,
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: "none",
          },
        },
      },
      MuiDrawer: {
        styleOverrides: {
          paper: ({ theme }) => ({
            ...customScrollbar,
          }),
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            ...customScrollbar,
          },
        },
      },
    },
  });
}
