var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { IsArray, IsBoolean, IsDate, IsEmail, IsIP, IsInt, IsMobilePhone, IsOptional, IsString, IsUUID, } from "class-validator";
import { Transform, Type } from "class-transformer";
import { PaginatedRequest } from "./find-all-request";
export class GetWebsiteSectionDto {
    agency_id;
    section_slug;
}
__decorate([
    IsUUID(),
    __metadata("design:type", String)
], GetWebsiteSectionDto.prototype, "agency_id", void 0);
__decorate([
    IsString(),
    __metadata("design:type", String)
], GetWebsiteSectionDto.prototype, "section_slug", void 0);
export class GetWebsiteProfileDto {
    agency_id;
}
__decorate([
    IsUUID(),
    __metadata("design:type", String)
], GetWebsiteProfileDto.prototype, "agency_id", void 0);
export class WebsiteTrackDto {
    agency_id;
    property_id;
    poll_id;
    url;
    user_agent;
    ip_address;
    page;
}
__decorate([
    IsUUID(),
    __metadata("design:type", String)
], WebsiteTrackDto.prototype, "agency_id", void 0);
__decorate([
    IsOptional(),
    IsUUID(),
    __metadata("design:type", String)
], WebsiteTrackDto.prototype, "property_id", void 0);
__decorate([
    IsOptional(),
    IsUUID(),
    __metadata("design:type", String)
], WebsiteTrackDto.prototype, "poll_id", void 0);
__decorate([
    IsString(),
    __metadata("design:type", String)
], WebsiteTrackDto.prototype, "url", void 0);
__decorate([
    IsString(),
    __metadata("design:type", String)
], WebsiteTrackDto.prototype, "user_agent", void 0);
__decorate([
    IsIP(),
    __metadata("design:type", String)
], WebsiteTrackDto.prototype, "ip_address", void 0);
__decorate([
    IsString(),
    __metadata("design:type", String)
], WebsiteTrackDto.prototype, "page", void 0);
export class WebsiteAddNewsletterSubscriptionDto {
    agency_id;
    email;
    language_code;
}
__decorate([
    IsUUID(),
    __metadata("design:type", String)
], WebsiteAddNewsletterSubscriptionDto.prototype, "agency_id", void 0);
__decorate([
    IsEmail(),
    __metadata("design:type", String)
], WebsiteAddNewsletterSubscriptionDto.prototype, "email", void 0);
__decorate([
    IsString(),
    __metadata("design:type", String)
], WebsiteAddNewsletterSubscriptionDto.prototype, "language_code", void 0);
export class WebsiteSendMessageDto {
    agency_id;
    language_code;
    name;
    email;
    mobile;
    message;
    accept_legal_terms;
    accept_communications;
    property_reference;
}
__decorate([
    IsUUID(),
    __metadata("design:type", String)
], WebsiteSendMessageDto.prototype, "agency_id", void 0);
__decorate([
    IsString(),
    __metadata("design:type", String)
], WebsiteSendMessageDto.prototype, "language_code", void 0);
__decorate([
    IsString(),
    __metadata("design:type", String)
], WebsiteSendMessageDto.prototype, "name", void 0);
__decorate([
    IsEmail(),
    __metadata("design:type", String)
], WebsiteSendMessageDto.prototype, "email", void 0);
__decorate([
    IsMobilePhone(),
    __metadata("design:type", String)
], WebsiteSendMessageDto.prototype, "mobile", void 0);
__decorate([
    IsString(),
    __metadata("design:type", String)
], WebsiteSendMessageDto.prototype, "message", void 0);
__decorate([
    IsBoolean(),
    __metadata("design:type", Boolean)
], WebsiteSendMessageDto.prototype, "accept_legal_terms", void 0);
__decorate([
    IsOptional(),
    IsBoolean(),
    __metadata("design:type", Boolean)
], WebsiteSendMessageDto.prototype, "accept_communications", void 0);
__decorate([
    IsOptional(),
    IsString(),
    __metadata("design:type", String)
], WebsiteSendMessageDto.prototype, "property_reference", void 0);
export class WebsiteGetPropertyDto {
    agency_id;
    reference;
}
__decorate([
    IsUUID(),
    __metadata("design:type", String)
], WebsiteGetPropertyDto.prototype, "agency_id", void 0);
__decorate([
    IsString(),
    __metadata("design:type", String)
], WebsiteGetPropertyDto.prototype, "reference", void 0);
export class WebsiteFilterPropertiesDto extends PaginatedRequest {
    agency_id;
    property_type_id;
    transaction_type_id;
    geo_zone_id;
    min_price;
    max_price;
    reference;
    property_subtype_id;
    min_bedrooms;
    min_bathrooms;
    min_surface;
    extras;
    conservation;
    available;
}
__decorate([
    IsUUID(),
    __metadata("design:type", String)
], WebsiteFilterPropertiesDto.prototype, "agency_id", void 0);
__decorate([
    IsOptional(),
    IsInt(),
    Type(() => Number),
    __metadata("design:type", Number)
], WebsiteFilterPropertiesDto.prototype, "property_type_id", void 0);
__decorate([
    IsOptional(),
    IsInt(),
    Type(() => Number),
    __metadata("design:type", Number)
], WebsiteFilterPropertiesDto.prototype, "transaction_type_id", void 0);
__decorate([
    IsOptional(),
    IsString(),
    __metadata("design:type", String)
], WebsiteFilterPropertiesDto.prototype, "geo_zone_id", void 0);
__decorate([
    IsOptional(),
    IsInt(),
    Type(() => Number),
    __metadata("design:type", Number)
], WebsiteFilterPropertiesDto.prototype, "min_price", void 0);
__decorate([
    IsOptional(),
    IsInt(),
    Type(() => Number),
    __metadata("design:type", Number)
], WebsiteFilterPropertiesDto.prototype, "max_price", void 0);
__decorate([
    IsOptional(),
    IsString(),
    __metadata("design:type", String)
], WebsiteFilterPropertiesDto.prototype, "reference", void 0);
__decorate([
    IsOptional(),
    IsInt(),
    Type(() => Number),
    __metadata("design:type", Number)
], WebsiteFilterPropertiesDto.prototype, "property_subtype_id", void 0);
__decorate([
    IsOptional(),
    IsInt(),
    Type(() => Number),
    __metadata("design:type", Number)
], WebsiteFilterPropertiesDto.prototype, "min_bedrooms", void 0);
__decorate([
    IsOptional(),
    IsInt(),
    Type(() => Number),
    __metadata("design:type", Number)
], WebsiteFilterPropertiesDto.prototype, "min_bathrooms", void 0);
__decorate([
    IsOptional(),
    IsInt(),
    Type(() => Number),
    __metadata("design:type", Number)
], WebsiteFilterPropertiesDto.prototype, "min_surface", void 0);
__decorate([
    IsOptional(),
    IsArray(),
    IsString({ each: true }),
    Transform(({ value }) => typeof value === "string"
        ? value.toString().split(",")
        : value instanceof Array
            ? value
            : null),
    __metadata("design:type", Array)
], WebsiteFilterPropertiesDto.prototype, "extras", void 0);
__decorate([
    IsOptional(),
    IsArray(),
    IsString({ each: true }),
    Transform(({ value }) => typeof value === "string"
        ? value.toString().split(",")
        : value instanceof Array
            ? value
            : null),
    __metadata("design:type", Array)
], WebsiteFilterPropertiesDto.prototype, "conservation", void 0);
__decorate([
    IsOptional(),
    IsBoolean(),
    Transform(({ value }) => value === "true"),
    __metadata("design:type", Boolean)
], WebsiteFilterPropertiesDto.prototype, "available", void 0);
export class UpdateWebsiteConfiguration {
    menu;
    sections;
    default_language_code;
}
export class WebsiteStatisticsDto {
    from_date;
    to_date;
}
__decorate([
    IsDate(),
    Type(() => Date),
    __metadata("design:type", Date)
], WebsiteStatisticsDto.prototype, "from_date", void 0);
__decorate([
    IsDate(),
    Type(() => Date),
    __metadata("design:type", Date)
], WebsiteStatisticsDto.prototype, "to_date", void 0);
export class WebsiteStatisticsItem {
    date;
    visits;
}
export class GetWebsitePollDto {
    agency_id;
    poll_id;
    poll_result_id;
    language_code;
}
__decorate([
    IsUUID(),
    __metadata("design:type", String)
], GetWebsitePollDto.prototype, "agency_id", void 0);
__decorate([
    IsUUID(),
    __metadata("design:type", String)
], GetWebsitePollDto.prototype, "poll_id", void 0);
__decorate([
    IsUUID(),
    __metadata("design:type", String)
], GetWebsitePollDto.prototype, "poll_result_id", void 0);
__decorate([
    IsString(),
    __metadata("design:type", String)
], GetWebsitePollDto.prototype, "language_code", void 0);
export class WebsitePollSubmitDto {
    agency_id;
    poll_id;
    poll_result_id;
    comments;
    answers;
}
__decorate([
    IsUUID(),
    __metadata("design:type", String)
], WebsitePollSubmitDto.prototype, "agency_id", void 0);
__decorate([
    IsUUID(),
    __metadata("design:type", String)
], WebsitePollSubmitDto.prototype, "poll_id", void 0);
__decorate([
    IsUUID(),
    __metadata("design:type", String)
], WebsitePollSubmitDto.prototype, "poll_result_id", void 0);
__decorate([
    IsOptional(),
    IsString(),
    __metadata("design:type", String)
], WebsitePollSubmitDto.prototype, "comments", void 0);
__decorate([
    IsArray(),
    __metadata("design:type", Array)
], WebsitePollSubmitDto.prototype, "answers", void 0);
export class WebsitePollSubmitAnswerDto {
    question_id;
    option_id;
}
__decorate([
    IsUUID(),
    __metadata("design:type", String)
], WebsitePollSubmitAnswerDto.prototype, "question_id", void 0);
__decorate([
    IsUUID(),
    __metadata("design:type", String)
], WebsitePollSubmitAnswerDto.prototype, "option_id", void 0);
