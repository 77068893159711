import { parseException } from "../errors";
export class PlansController {
    constructor(axios) {
        this.axios = axios;
    }
    async getAvailables() {
        try {
            const response = await this.axios.get(`/plans/availables`);
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async getVersion(planVersionId) {
        try {
            const response = await this.axios.get(`/plans/version/${planVersionId}`);
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
}
