import { parseException } from "../errors";
export class CalendarController {
    constructor(axios) {
        this.axios = axios;
    }
    async getEvent(eventId) {
        try {
            const response = await this.axios.get(`/calendar/events/${eventId}`);
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async deleteEvent(eventId) {
        try {
            const response = await this.axios.delete(`/calendar/events/${eventId}`);
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async updateEvent(eventId, dto) {
        try {
            const response = await this.axios.patch(`/calendar/events/${eventId}`, dto);
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async createEvent(dto) {
        try {
            const response = await this.axios.post(`/calendar/events`, dto);
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
}
