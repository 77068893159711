export class MeansController {
    constructor(axios) {
        this.axios = axios;
    }
    async findOne(id) {
        try {
            const response = await this.axios.get(`/means/${id}`);
            return response.data;
        }
        catch (e) {
            throw e;
        }
    }
    async find() {
        try {
            const response = await this.axios.get("/means");
            return response.data;
        }
        catch (e) {
            throw e;
        }
    }
}
