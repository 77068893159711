export class GeoController {
    constructor(axios) {
        this.axios = axios;
    }
    async getZones(dto) {
        try {
            const response = await this.axios.get(`/geo/zones`, {
                params: dto,
            });
            return response.data;
        }
        catch (e) {
            throw e;
        }
    }
    async getZone(id) {
        try {
            const response = await this.axios.get(`/geo/zones/${id}`);
            return response.data;
        }
        catch (e) {
            throw e;
        }
    }
    async autocomplete(input, session, type) {
        try {
            const response = await this.axios.get("/geo/autocomplete", {
                params: { input, session, type },
            });
            return response.data;
        }
        catch (e) {
            throw e;
        }
    }
    async place(place_id) {
        try {
            const response = await this.axios.get("/geo/place", {
                params: { place_id },
            });
            return response.data;
        }
        catch (e) {
            throw e;
        }
    }
    async detail(id) {
        try {
            const response = await this.axios.get(`/geo/detail/${id}`);
            return response.data;
        }
        catch (e) {
            throw e;
        }
    }
}
