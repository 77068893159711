import { Box, useTheme, Typography } from "@mui/material";

export function BannerWebsiteDemo() {

  const theme = useTheme();

  return (
    <Box
      sx={{
        zIndex: 1000,
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.dark,
        borderTop: `1px solid ${theme.palette.primary.main}`,
        px: 3,
        py: 2,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center"
      }}
    >
      <Typography variant="body2">
      Los anuncios que aparecen son ficticios. Cuando crees y publiques tu primer
      inmueble, se activarán los anuncios reales.
      </Typography>

    </Box>
  );
}