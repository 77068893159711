import { parseException } from "../errors";
export class WebsiteController {
    constructor(axios) {
        this.axios = axios;
    }
    async getStatistics(dto) {
        try {
            const response = await this.axios.post("/website/statistics", dto);
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async getPoll(dto) {
        try {
            const response = await this.axios.get("/website/poll", {
                params: dto,
            });
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async submitPoll(dto) {
        try {
            await this.axios.post("/website/poll/submit", dto);
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async track(dto) {
        try {
            await this.axios.post("/website/track", dto);
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async sendMessage(dto) {
        try {
            await this.axios.post("/website/send-message", dto);
        }
        catch (e) {
            throw e;
        }
    }
    async getProperty(dto) {
        try {
            const response = await this.axios.get("/website/property", { params: dto });
            return response.data;
        }
        catch (e) {
            throw e;
        }
    }
    async filterProperties(dto) {
        try {
            const response = await this.axios.get("/website/properties", { params: dto });
            return response.data;
        }
        catch (e) {
            throw e;
        }
    }
    async countProperties(dto) {
        try {
            const response = await this.axios.get("/website/properties/count", { params: dto });
            return response.data;
        }
        catch (e) {
            throw e;
        }
    }
    async getProfile(dto) {
        try {
            const response = await this.axios.get("/website/profile", { params: dto });
            return response.data;
        }
        catch (e) {
            throw e;
        }
    }
    async getConfig() {
        try {
            const response = await this.axios.get("/website/config");
            return response.data;
        }
        catch (e) {
            throw e;
        }
    }
    async updateConfig(dto) {
        try {
            const response = await this.axios.post("/website/config", dto);
            return response.data;
        }
        catch (e) {
            throw e;
        }
    }
    async renderSection(dto) {
        try {
            const response = await this.axios.get("/website/render-section", { params: dto });
            return response.data;
        }
        catch (e) {
            throw e;
        }
    }
    async addNewsletterSubscription(dto) {
        try {
            const response = await this.axios.post("/website/add-newsletter-subscription", dto);
            return response.data;
        }
        catch (e) {
            throw e;
        }
    }
}
