export function UseCache(options) {
    var _a;
    const cache = new Map();
    const ttlMiliseconds = ((_a = options.ttl) !== null && _a !== void 0 ? _a : 60 * 10) * 1000;
    return (_target, _propertyKey, descriptor) => {
        const originalFunction = descriptor.value;
        descriptor.value = async function (...args) {
            const cacheKey = options.keyFactory
                ? options.keyFactory(...args)
                : JSON.stringify(args);
            const cachedValue = cache.get(cacheKey);
            if (cachedValue && cachedValue.expiry > Date.now()) {
                return cachedValue.value;
            }
            const result = await originalFunction.apply(this, args);
            cache.set(cacheKey, {
                expiry: Date.now() + ttlMiliseconds,
                value: result,
            });
            return result;
        };
        return descriptor;
    };
}
