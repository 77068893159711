import React, { AnchorHTMLAttributes } from "react";
import Link from "next/link";

const LinkBehavior = React.forwardRef<
  any,
  AnchorHTMLAttributes<HTMLAnchorElement>
>(function LinkBehavior(props, ref) {
  const { href, children, ...other } = props;
  if (href && href.toString().indexOf("http") === 0) {
    return (
      <a ref={ref} href={href.toString()} {...other}>
        {children}
      </a>
    );
  } else {
    return (
      <Link
        locale={false}
        prefetch={false}
        ref={ref}
        href={href ?? "/"}
        {...other}
      >
        {children}
      </Link>
    );
  }
});

export default LinkBehavior;
