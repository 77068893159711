import { ListItemButton } from "@mui/material";
import { MouseEventHandler } from "react";
import { RxCaretRight } from "react-icons/rx";

export type MenuItemMobileProps = {
  href: string;
  label: string;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
};

export default function MenuItemMobile({
  href,
  label,
  onClick,
}: MenuItemMobileProps) {
  return (
    <ListItemButton
      onClick={onClick}
      href={href}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        gap: 1,
        py: 1.5,
        borderBottomStyle: "solid",
        borderBottomWidth: 1,
        borderBottomColor: (theme) => theme.palette.divider,
        "&:last-child": {
          borderBottom: "none",
        },
      }}
    >
      {label} <RxCaretRight size={22} />
    </ListItemButton>
  );
}
