import { parseException } from "../errors";
export class WalletsController {
    constructor(axios) {
        this.axios = axios;
    }
    async getWallets() {
        try {
            const response = await this.axios.get(`/wallets`);
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async getCreditsAvailable(walletType) {
        try {
            const response = await this.axios.get(`/wallets/${walletType}/credits-available`);
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
}
