import { parseException } from "../errors";
export class AgendaController {
    constructor(axios) {
        this.axios = axios;
    }
    async getEvents() {
        try {
            const response = await this.axios.get("/agenda/events");
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async editEventPeriod(id, dto) {
        try {
            const response = await this.axios.post(`/agenda/events/${id}/change-period`, dto);
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
}
