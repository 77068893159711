var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { IsBoolean, IsDate, IsEmail, IsEnum, IsHexColor, IsNumber, IsOptional, IsString, IsUUID, IsUrl, ValidateNested, } from "class-validator";
import { Type } from "class-transformer";
export class AgencyOnboardingInfo {
    numberOfAgents;
    numberOfProperties;
    highQualityPhotos;
}
__decorate([
    IsEnum(["1", "2~5", "+5"]),
    __metadata("design:type", String)
], AgencyOnboardingInfo.prototype, "numberOfAgents", void 0);
__decorate([
    IsEnum(["-10", "10~30", "+30"]),
    __metadata("design:type", String)
], AgencyOnboardingInfo.prototype, "numberOfProperties", void 0);
__decorate([
    IsBoolean(),
    __metadata("design:type", Boolean)
], AgencyOnboardingInfo.prototype, "highQualityPhotos", void 0);
export class AgencySocialMedias {
    twitter;
    facebook;
    instagram;
    linkedin;
    pinterest;
}
__decorate([
    IsOptional(),
    IsUrl(),
    __metadata("design:type", String)
], AgencySocialMedias.prototype, "twitter", void 0);
__decorate([
    IsOptional(),
    IsUrl(),
    __metadata("design:type", String)
], AgencySocialMedias.prototype, "facebook", void 0);
__decorate([
    IsOptional(),
    IsUrl(),
    __metadata("design:type", String)
], AgencySocialMedias.prototype, "instagram", void 0);
__decorate([
    IsOptional(),
    IsUrl(),
    __metadata("design:type", String)
], AgencySocialMedias.prototype, "linkedin", void 0);
__decorate([
    IsOptional(),
    IsUrl(),
    __metadata("design:type", String)
], AgencySocialMedias.prototype, "pinterest", void 0);
export class AgencyPalette {
    primary;
    secondary;
    error;
    warning;
    info;
    success;
}
__decorate([
    IsOptional(),
    IsHexColor(),
    __metadata("design:type", String)
], AgencyPalette.prototype, "primary", void 0);
__decorate([
    IsOptional(),
    IsHexColor(),
    __metadata("design:type", String)
], AgencyPalette.prototype, "secondary", void 0);
__decorate([
    IsOptional(),
    IsHexColor(),
    __metadata("design:type", String)
], AgencyPalette.prototype, "error", void 0);
__decorate([
    IsOptional(),
    IsHexColor(),
    __metadata("design:type", String)
], AgencyPalette.prototype, "warning", void 0);
__decorate([
    IsOptional(),
    IsHexColor(),
    __metadata("design:type", String)
], AgencyPalette.prototype, "info", void 0);
__decorate([
    IsOptional(),
    IsHexColor(),
    __metadata("design:type", String)
], AgencyPalette.prototype, "success", void 0);
export class AgencyUserInvitation {
    id;
    agency_id;
    agent_id;
    email;
    role_id;
    created_at;
    accepted_at;
    rejected_at;
    expired_at;
    invitation_code;
    answered_by_user_id;
}
export class UpdateAgentDto {
    fullname;
    role_id;
}
__decorate([
    IsString(),
    __metadata("design:type", String)
], UpdateAgentDto.prototype, "fullname", void 0);
__decorate([
    IsUUID(),
    __metadata("design:type", String)
], UpdateAgentDto.prototype, "role_id", void 0);
export class CreateAgentDto {
    fullname;
    email;
    role_id;
}
__decorate([
    IsString(),
    __metadata("design:type", String)
], CreateAgentDto.prototype, "fullname", void 0);
__decorate([
    IsEmail(),
    __metadata("design:type", String)
], CreateAgentDto.prototype, "email", void 0);
__decorate([
    IsUUID(),
    __metadata("design:type", String)
], CreateAgentDto.prototype, "role_id", void 0);
export class InviteAgentDto {
    email;
    role_id;
}
__decorate([
    IsEmail(),
    __metadata("design:type", String)
], InviteAgentDto.prototype, "email", void 0);
__decorate([
    IsUUID(),
    __metadata("design:type", String)
], InviteAgentDto.prototype, "role_id", void 0);
export class DetectAgencyDto {
    hostname;
    explicit_agency_id;
}
__decorate([
    IsOptional(),
    IsString(),
    __metadata("design:type", String)
], DetectAgencyDto.prototype, "hostname", void 0);
__decorate([
    IsOptional(),
    IsString(),
    __metadata("design:type", String)
], DetectAgencyDto.prototype, "explicit_agency_id", void 0);
export class UpdateAgencyFiscalDataDto {
    fiscal_number;
    address;
    business_name;
    invoice_email_notification;
}
__decorate([
    IsString(),
    __metadata("design:type", String)
], UpdateAgencyFiscalDataDto.prototype, "fiscal_number", void 0);
__decorate([
    IsString(),
    __metadata("design:type", String)
], UpdateAgencyFiscalDataDto.prototype, "address", void 0);
__decorate([
    IsString(),
    __metadata("design:type", String)
], UpdateAgencyFiscalDataDto.prototype, "business_name", void 0);
__decorate([
    IsEmail(),
    IsOptional(),
    __metadata("design:type", String)
], UpdateAgencyFiscalDataDto.prototype, "invoice_email_notification", void 0);
export class PatchAgencyDto {
    logotype_document_id;
    logotype_square_document_id;
    comercial_name;
    email;
    mobile;
    geo_address_id;
    palette;
    social_medias;
    languages;
}
__decorate([
    IsOptional(),
    IsUUID(),
    __metadata("design:type", String)
], PatchAgencyDto.prototype, "logotype_document_id", void 0);
__decorate([
    IsOptional(),
    IsUUID(),
    __metadata("design:type", String)
], PatchAgencyDto.prototype, "logotype_square_document_id", void 0);
__decorate([
    IsOptional(),
    IsString(),
    __metadata("design:type", String)
], PatchAgencyDto.prototype, "comercial_name", void 0);
__decorate([
    IsOptional(),
    IsString(),
    __metadata("design:type", String)
], PatchAgencyDto.prototype, "email", void 0);
__decorate([
    IsOptional(),
    IsString(),
    __metadata("design:type", String)
], PatchAgencyDto.prototype, "mobile", void 0);
__decorate([
    IsOptional(),
    IsNumber(),
    __metadata("design:type", Number)
], PatchAgencyDto.prototype, "geo_address_id", void 0);
__decorate([
    IsOptional(),
    ValidateNested(),
    __metadata("design:type", AgencyPalette)
], PatchAgencyDto.prototype, "palette", void 0);
__decorate([
    IsOptional(),
    ValidateNested(),
    __metadata("design:type", AgencySocialMedias)
], PatchAgencyDto.prototype, "social_medias", void 0);
__decorate([
    IsOptional(),
    IsString({ each: true }),
    __metadata("design:type", Array)
], PatchAgencyDto.prototype, "languages", void 0);
export class RegisterAgencyDomainDto {
    domain;
}
__decorate([
    IsString(),
    __metadata("design:type", String)
], RegisterAgencyDomainDto.prototype, "domain", void 0);
export class VerifyAgencyDomainDto {
    domain;
}
__decorate([
    IsString(),
    __metadata("design:type", String)
], VerifyAgencyDomainDto.prototype, "domain", void 0);
export class UpdateCertificateStatusAgencyDomainDto {
    agency_id;
    domain;
    certificate_ssl_generated_at;
    certificate_ssl_expires_at;
}
__decorate([
    IsUUID(),
    __metadata("design:type", String)
], UpdateCertificateStatusAgencyDomainDto.prototype, "agency_id", void 0);
__decorate([
    IsString(),
    __metadata("design:type", String)
], UpdateCertificateStatusAgencyDomainDto.prototype, "domain", void 0);
__decorate([
    IsOptional(),
    IsDate(),
    Type(() => Date),
    __metadata("design:type", Date)
], UpdateCertificateStatusAgencyDomainDto.prototype, "certificate_ssl_generated_at", void 0);
__decorate([
    IsOptional(),
    IsDate(),
    Type(() => Date),
    __metadata("design:type", Date)
], UpdateCertificateStatusAgencyDomainDto.prototype, "certificate_ssl_expires_at", void 0);
export class AgencyConfigureIntegration {
    channel_code;
    config;
}
__decorate([
    IsString(),
    __metadata("design:type", String)
], AgencyConfigureIntegration.prototype, "channel_code", void 0);
