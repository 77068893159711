import { AgencyWebsiteProfile } from "@serviestate/shared";
import { NextIncomingMessage } from "next/dist/server/request-meta";

export function getSectionLink(
  agencyProfile: AgencyWebsiteProfile,
  sectionId: string,
  language: string
) {
  if (!agencyProfile) return "/";

  const section = agencyProfile.sections.find((x) => x.id === sectionId);
  if (!section) return `/${language}/`;
  const slug =
    typeof section.slug === "string"
      ? section.slug
      : section.slug[language] ?? section.slug[Object.keys(section.slug)[0]];
  return `/${language}/${slug ?? "es"}`;
}

export function getAgencyIdFromRequest(req?: NextIncomingMessage) {
  const agencyId = req?.headers?.["x-agency-id"] as string;
  return agencyId;
}

export function getLanguageFromRequest(req?: NextIncomingMessage) {
  const meta = (req as any)[Symbol.for("NextInternalRequestMeta")];
  return (
    (req?.headers?.["x-language"] as string) ??
    meta?._nextMatch?.detectedLocale ??
    "es"
  );
}

export function getTranslation<TType>(
  language: string,
  obj: Record<string, TType> | string,
  alternativeLanguage?: string
): TType | null {
  if (obj === undefined || obj === null) return null;
  if (typeof obj === "string") return obj as TType;
  let result: TType | null = obj[language];
  if (!result) result = obj[alternativeLanguage ?? "es"];
  if (!result) result = obj[Object.keys(obj)[0]];
  if (!result) result = null;
  return result;
}

export function getTranslationFromArray<
  TType extends { language_code: string }
>(language: string, arr: TType[], alternativeLanguage?: string): TType | null {
  let result: TType | null =
    arr.find((x) => x.language_code === language) ?? null;
  if (!result && alternativeLanguage)
    result = arr.find((x) => x.language_code === alternativeLanguage) ?? null;
  if (!result && language !== "es")
    result = arr.find((x) => x.language_code === "es") ?? null;
  if (!result && language !== "en")
    result = arr.find((x) => x.language_code === "en") ?? null;
  if (!result) result = arr[0];
  if (!result) result = null;
  return result;
}

export function getContrastColor(hexColor: string) {
  if (hexColor.indexOf("#") === 0) {
    hexColor = hexColor.slice(1);
  }

  // Convertir a valores RGB
  var r = parseInt(hexColor.substring(0, 2), 16);
  var g = parseInt(hexColor.substring(2, 4), 16);
  var b = parseInt(hexColor.substring(4, 6), 16);

  // Calcular la luminancia del color
  var luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

  // Determinar si el color es claro u oscuro
  if (luminance > 0.5) {
    return "#000000"; // Negro para fondos claros
  } else {
    return "#FFFFFF"; // Blanco para fondos oscuros
  }
}
