export class ProjectsController {
    constructor(axios) {
        this.axios = axios;
    }
    async find(filter) {
        try {
            const response = await this.axios.get("/projects", {
                params: filter,
            });
            return response.data;
        }
        catch (e) {
            throw e;
        }
    }
    async detail(id) {
        try {
            const response = await this.axios.get(`/projects/${id}`);
            return response.data;
        }
        catch (e) {
            throw e;
        }
    }
    async count(filter) {
        try {
            const response = await this.axios.get("/projects/count", {
                params: filter,
            });
            return response.data;
        }
        catch (e) {
            throw e;
        }
    }
    async create(data) {
        try {
            const response = await this.axios.post("/projects", data);
            return response.data;
        }
        catch (e) {
            throw e;
        }
    }
    async update(id, data) {
        try {
            const response = await this.axios.patch(`/projects/${id}`, data);
            return response.data;
        }
        catch (e) {
            throw e;
        }
    }
    async delete(id) {
        try {
            const response = await this.axios.delete(`/projects/${id}`);
            return response.data;
        }
        catch (e) {
            throw e;
        }
    }
    async createProperty(dto) {
        try {
            const response = await this.axios.post(`/projects/create-property`, dto);
            return response.data;
        }
        catch (e) {
            throw e;
        }
    }
    async getProperties(projectId) {
        try {
            const response = await this.axios.get(`/projects/${projectId}/properties`);
            return response.data;
        }
        catch (e) {
            throw e;
        }
    }
    async deleteProperty(projectId, propertyId) {
        try {
            await this.axios.delete(`/projects/${projectId}/property/${propertyId}`);
            return true;
        }
        catch (e) {
            throw e;
        }
    }
}
