export class IdealistaController {
    constructor(axios) {
        this.axios = axios;
    }
    async validateILC(dto) {
        try {
            const response = await this.axios.post("/idealista/validate-ilc", dto);
            return response.data;
        }
        catch (e) {
            throw e;
        }
    }
}
