var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { IsInt, IsString, IsOptional, IsNotEmpty, IsBoolean, IsNumber, IsArray, ValidateNested, IsObject, IsDate, IsUUID, IsEnum, } from "class-validator";
import { Transform, Type } from "class-transformer";
import { FindAllRequest, PaginatedRequest } from "./find-all-request";
export class UpdateExternalPropertyBulkSummaryDto {
    total_properties;
}
__decorate([
    IsNumber(),
    __metadata("design:type", Number)
], UpdateExternalPropertyBulkSummaryDto.prototype, "total_properties", void 0);
export class UpdateExternalPropertyBulkProgressDto {
    provider_property_id;
    external_property_id;
    status;
    error;
}
__decorate([
    IsString(),
    __metadata("design:type", String)
], UpdateExternalPropertyBulkProgressDto.prototype, "provider_property_id", void 0);
__decorate([
    IsOptional(),
    IsUUID(),
    __metadata("design:type", String)
], UpdateExternalPropertyBulkProgressDto.prototype, "external_property_id", void 0);
__decorate([
    IsEnum(["pending", "processing", "imported", "error"]),
    __metadata("design:type", String)
], UpdateExternalPropertyBulkProgressDto.prototype, "status", void 0);
__decorate([
    IsOptional(),
    IsString(),
    __metadata("design:type", String)
], UpdateExternalPropertyBulkProgressDto.prototype, "error", void 0);
export class CreateExternalContactDto {
    fullname;
    phone;
    website;
    address;
    description;
    is_professional;
    external_reference;
    email;
}
__decorate([
    IsString(),
    __metadata("design:type", String)
], CreateExternalContactDto.prototype, "fullname", void 0);
__decorate([
    IsOptional(),
    IsString(),
    __metadata("design:type", String)
], CreateExternalContactDto.prototype, "phone", void 0);
__decorate([
    IsOptional(),
    IsString(),
    __metadata("design:type", String)
], CreateExternalContactDto.prototype, "website", void 0);
__decorate([
    IsOptional(),
    IsString(),
    __metadata("design:type", String)
], CreateExternalContactDto.prototype, "address", void 0);
__decorate([
    IsOptional(),
    IsString(),
    __metadata("design:type", String)
], CreateExternalContactDto.prototype, "description", void 0);
__decorate([
    IsNotEmpty(),
    IsBoolean(),
    __metadata("design:type", Boolean)
], CreateExternalContactDto.prototype, "is_professional", void 0);
__decorate([
    IsOptional(),
    IsString(),
    __metadata("design:type", String)
], CreateExternalContactDto.prototype, "external_reference", void 0);
__decorate([
    IsOptional(),
    IsString(),
    __metadata("design:type", String)
], CreateExternalContactDto.prototype, "email", void 0);
export class CreateExternalPropertyDto {
    transaction_type_id;
    property_subtype_id;
    address;
    is_approximate;
    address2;
    latitude;
    longitude;
    bathrooms;
    bedrooms;
    useful_surface;
    plot_surface;
    builded_surface;
    conservation;
    extras;
    channel_code;
    channel_ref;
    provider_ref;
    price;
    old_price;
    photos;
    link;
    description;
    contact;
    bank;
}
__decorate([
    IsInt(),
    __metadata("design:type", Number)
], CreateExternalPropertyDto.prototype, "transaction_type_id", void 0);
__decorate([
    IsInt(),
    __metadata("design:type", Number)
], CreateExternalPropertyDto.prototype, "property_subtype_id", void 0);
__decorate([
    IsString(),
    __metadata("design:type", String)
], CreateExternalPropertyDto.prototype, "address", void 0);
__decorate([
    IsBoolean(),
    __metadata("design:type", Boolean)
], CreateExternalPropertyDto.prototype, "is_approximate", void 0);
__decorate([
    IsOptional(),
    IsString(),
    __metadata("design:type", String)
], CreateExternalPropertyDto.prototype, "address2", void 0);
__decorate([
    IsOptional(),
    __metadata("design:type", Number)
], CreateExternalPropertyDto.prototype, "latitude", void 0);
__decorate([
    IsOptional(),
    __metadata("design:type", Number)
], CreateExternalPropertyDto.prototype, "longitude", void 0);
__decorate([
    IsOptional(),
    IsInt(),
    __metadata("design:type", Number)
], CreateExternalPropertyDto.prototype, "bathrooms", void 0);
__decorate([
    IsOptional(),
    IsInt(),
    __metadata("design:type", Number)
], CreateExternalPropertyDto.prototype, "bedrooms", void 0);
__decorate([
    IsOptional(),
    IsInt(),
    __metadata("design:type", Number)
], CreateExternalPropertyDto.prototype, "useful_surface", void 0);
__decorate([
    IsOptional(),
    IsInt(),
    __metadata("design:type", Number)
], CreateExternalPropertyDto.prototype, "plot_surface", void 0);
__decorate([
    IsOptional(),
    IsInt(),
    __metadata("design:type", Number)
], CreateExternalPropertyDto.prototype, "builded_surface", void 0);
__decorate([
    IsOptional(),
    IsString(),
    __metadata("design:type", String)
], CreateExternalPropertyDto.prototype, "conservation", void 0);
__decorate([
    IsOptional(),
    IsArray(),
    __metadata("design:type", Array)
], CreateExternalPropertyDto.prototype, "extras", void 0);
__decorate([
    IsString(),
    __metadata("design:type", String)
], CreateExternalPropertyDto.prototype, "channel_code", void 0);
__decorate([
    IsNotEmpty(),
    IsString(),
    __metadata("design:type", String)
], CreateExternalPropertyDto.prototype, "channel_ref", void 0);
__decorate([
    IsOptional(),
    IsString(),
    __metadata("design:type", String)
], CreateExternalPropertyDto.prototype, "provider_ref", void 0);
__decorate([
    IsOptional(),
    IsNumber(),
    __metadata("design:type", Number)
], CreateExternalPropertyDto.prototype, "price", void 0);
__decorate([
    IsOptional(),
    IsNumber(),
    __metadata("design:type", Number)
], CreateExternalPropertyDto.prototype, "old_price", void 0);
__decorate([
    IsOptional(),
    IsArray(),
    __metadata("design:type", Array)
], CreateExternalPropertyDto.prototype, "photos", void 0);
__decorate([
    IsNotEmpty(),
    IsString(),
    __metadata("design:type", String)
], CreateExternalPropertyDto.prototype, "link", void 0);
__decorate([
    IsOptional(),
    IsString(),
    __metadata("design:type", String)
], CreateExternalPropertyDto.prototype, "description", void 0);
__decorate([
    ValidateNested(),
    __metadata("design:type", CreateExternalContactDto)
], CreateExternalPropertyDto.prototype, "contact", void 0);
__decorate([
    IsBoolean(),
    __metadata("design:type", Boolean)
], CreateExternalPropertyDto.prototype, "bank", void 0);
export class SetExternalPropertyPublishStatusDto {
    channel_code;
    channel_ref;
    published;
}
__decorate([
    IsString(),
    __metadata("design:type", String)
], SetExternalPropertyPublishStatusDto.prototype, "channel_code", void 0);
__decorate([
    IsNotEmpty(),
    IsString(),
    __metadata("design:type", String)
], SetExternalPropertyPublishStatusDto.prototype, "channel_ref", void 0);
__decorate([
    IsBoolean(),
    __metadata("design:type", Boolean)
], SetExternalPropertyPublishStatusDto.prototype, "published", void 0);
export class InternalExternalPropertyFindAllDto extends PaginatedRequest {
    channel_code;
    channel_ref;
    not_checked_recently;
    published;
}
__decorate([
    IsString(),
    __metadata("design:type", String)
], InternalExternalPropertyFindAllDto.prototype, "channel_code", void 0);
__decorate([
    IsOptional(),
    IsString(),
    __metadata("design:type", String)
], InternalExternalPropertyFindAllDto.prototype, "channel_ref", void 0);
__decorate([
    IsOptional(),
    IsBoolean(),
    Transform(({ value }) => value === "true"),
    __metadata("design:type", Boolean)
], InternalExternalPropertyFindAllDto.prototype, "not_checked_recently", void 0);
__decorate([
    IsOptional(),
    IsBoolean(),
    Transform(({ value }) => value === "true"),
    __metadata("design:type", Boolean)
], InternalExternalPropertyFindAllDto.prototype, "published", void 0);
export class ExistsExternalContactDto {
    reference;
}
__decorate([
    IsString(),
    __metadata("design:type", String)
], ExistsExternalContactDto.prototype, "reference", void 0);
export var ExternalPropertyStatusEnum;
(function (ExternalPropertyStatusEnum) {
    ExternalPropertyStatusEnum[ExternalPropertyStatusEnum["NOT_EVALUATED"] = 1] = "NOT_EVALUATED";
    ExternalPropertyStatusEnum[ExternalPropertyStatusEnum["INTERESTED"] = 2] = "INTERESTED";
    ExternalPropertyStatusEnum[ExternalPropertyStatusEnum["CONTACTED"] = 3] = "CONTACTED";
    ExternalPropertyStatusEnum[ExternalPropertyStatusEnum["NEGOTIATING"] = 4] = "NEGOTIATING";
    ExternalPropertyStatusEnum[ExternalPropertyStatusEnum["ACHIEVED"] = 5] = "ACHIEVED";
    ExternalPropertyStatusEnum[ExternalPropertyStatusEnum["DISCARDED"] = 6] = "DISCARDED";
})(ExternalPropertyStatusEnum || (ExternalPropertyStatusEnum = {}));
export class CreateAgencyAcquisitionsAlertDto {
    filters;
    periodicity_id;
}
__decorate([
    IsOptional(),
    IsObject(),
    __metadata("design:type", Object)
], CreateAgencyAcquisitionsAlertDto.prototype, "filters", void 0);
__decorate([
    IsOptional(),
    IsInt(),
    __metadata("design:type", Number)
], CreateAgencyAcquisitionsAlertDto.prototype, "periodicity_id", void 0);
//#endregion
//#region "AgencyAcquisitionZone"
export class CreateAgencyAcquisitionZoneDto {
    geo_zone_id;
}
__decorate([
    IsUUID(),
    __metadata("design:type", String)
], CreateAgencyAcquisitionZoneDto.prototype, "geo_zone_id", void 0);
//#endregion
export class ExternalPropertyAgencyStatisticsRequest {
    from_date;
    to_date;
}
__decorate([
    IsDate(),
    Type(() => Date),
    __metadata("design:type", Date)
], ExternalPropertyAgencyStatisticsRequest.prototype, "from_date", void 0);
__decorate([
    IsDate(),
    Type(() => Date),
    __metadata("design:type", Date)
], ExternalPropertyAgencyStatisticsRequest.prototype, "to_date", void 0);
export class ExternalPropertyAgencyStatisticsItem {
    date;
    discard_count;
    acquired_count;
}
export class FindAllExternalPropertiesRequest extends FindAllRequest {
    channel_code;
    extras;
    geo_zone_id;
    origins;
    transaction_type_id;
    status_id;
    min_price;
    max_price;
    channel_ref;
    active;
    linked_with_agency;
    last_month;
    created_before_at;
    property_type_id;
    property_subtypes_id;
    rooms_min;
    bathrooms_min;
    surface_min;
    conservation;
}
__decorate([
    IsOptional(),
    IsString(),
    __metadata("design:type", String)
], FindAllExternalPropertiesRequest.prototype, "channel_code", void 0);
__decorate([
    Transform(({ value }) => typeof value === "string"
        ? value.toString().split(",")
        : value instanceof Array
            ? value
            : null),
    __metadata("design:type", Array)
], FindAllExternalPropertiesRequest.prototype, "extras", void 0);
__decorate([
    IsOptional(),
    IsString(),
    __metadata("design:type", String)
], FindAllExternalPropertiesRequest.prototype, "geo_zone_id", void 0);
__decorate([
    IsOptional(),
    IsArray(),
    IsNumber({}, { each: true }),
    Type(() => Number),
    __metadata("design:type", Array)
], FindAllExternalPropertiesRequest.prototype, "origins", void 0);
__decorate([
    IsOptional(),
    IsNumber(),
    Type(() => Number),
    __metadata("design:type", Number)
], FindAllExternalPropertiesRequest.prototype, "transaction_type_id", void 0);
__decorate([
    IsOptional(),
    IsNumber(),
    Type(() => Number),
    __metadata("design:type", Number)
], FindAllExternalPropertiesRequest.prototype, "status_id", void 0);
__decorate([
    IsOptional(),
    IsNumber(),
    Type(() => Number),
    __metadata("design:type", Number)
], FindAllExternalPropertiesRequest.prototype, "min_price", void 0);
__decorate([
    IsOptional(),
    IsNumber(),
    Type(() => Number),
    __metadata("design:type", Number)
], FindAllExternalPropertiesRequest.prototype, "max_price", void 0);
__decorate([
    IsOptional(),
    IsString(),
    __metadata("design:type", String)
], FindAllExternalPropertiesRequest.prototype, "channel_ref", void 0);
__decorate([
    IsOptional(),
    IsBoolean(),
    Transform(({ value }) => value === "true"),
    __metadata("design:type", Boolean)
], FindAllExternalPropertiesRequest.prototype, "active", void 0);
__decorate([
    IsOptional(),
    IsBoolean(),
    Transform(({ value }) => value === "true"),
    __metadata("design:type", Boolean)
], FindAllExternalPropertiesRequest.prototype, "linked_with_agency", void 0);
__decorate([
    IsOptional(),
    IsBoolean(),
    Transform(({ value }) => value === "true"),
    __metadata("design:type", Boolean)
], FindAllExternalPropertiesRequest.prototype, "last_month", void 0);
__decorate([
    IsOptional(),
    IsDate(),
    Type(() => Date),
    __metadata("design:type", Date)
], FindAllExternalPropertiesRequest.prototype, "created_before_at", void 0);
__decorate([
    IsOptional(),
    IsNumber(),
    Type(() => Number),
    __metadata("design:type", Number)
], FindAllExternalPropertiesRequest.prototype, "property_type_id", void 0);
__decorate([
    IsOptional(),
    IsNumber({}, { each: true }),
    Type(() => Number),
    __metadata("design:type", Array)
], FindAllExternalPropertiesRequest.prototype, "property_subtypes_id", void 0);
__decorate([
    IsOptional(),
    IsNumber(),
    Type(() => Number),
    __metadata("design:type", Number)
], FindAllExternalPropertiesRequest.prototype, "rooms_min", void 0);
__decorate([
    IsOptional(),
    IsNumber(),
    Type(() => Number),
    __metadata("design:type", Number)
], FindAllExternalPropertiesRequest.prototype, "bathrooms_min", void 0);
__decorate([
    IsOptional(),
    IsNumber(),
    Type(() => Number),
    __metadata("design:type", Number)
], FindAllExternalPropertiesRequest.prototype, "surface_min", void 0);
__decorate([
    IsOptional(),
    IsString(),
    __metadata("design:type", String)
], FindAllExternalPropertiesRequest.prototype, "conservation", void 0);
export class FindAllAlertSettingsRequest extends FindAllRequest {
    geo_zone_id;
    origin;
}
__decorate([
    IsOptional(),
    IsString(),
    __metadata("design:type", String)
], FindAllAlertSettingsRequest.prototype, "geo_zone_id", void 0);
__decorate([
    IsOptional(),
    IsNumber(),
    Type(() => Number),
    __metadata("design:type", Number)
], FindAllAlertSettingsRequest.prototype, "origin", void 0);
