import { parseException } from "../errors";
export class AttributesController {
    constructor(axios) {
        this.axios = axios;
    }
    async getOptions(attribute) {
        try {
            const response = await this.axios.get(`/attributes/${attribute}/options`);
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async getItem(attribute, id) {
        try {
            const response = await this.axios.get(`/attributes/${attribute}/${id}`);
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async getExtras() {
        try {
            const response = await this.axios.get(`/attributes/extras`);
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
}
