import { parseException } from "../errors";
export class SubscriptionsController {
    constructor(axios) {
        this.axios = axios;
    }
    async discardChangePlan() {
        try {
            const response = await this.axios.post(`/subscriptions/discard-change-plan`);
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async createPreInvoiceToChangePlan(dto) {
        try {
            const response = await this.axios.post(`/subscriptions/create-pre-invoice-to-change-plan`, dto);
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async changePlan(dto) {
        try {
            const response = await this.axios.post(`/subscriptions/change-plan`, dto);
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async calculatePriceToChange(dto) {
        try {
            const response = await this.axios.post(`/subscriptions/calculate-price/change`, dto);
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async calculateCurrentPrice() {
        try {
            const response = await this.axios.post(`/subscriptions/calculate-price/current`);
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async cancelRenewal() {
        try {
            const response = await this.axios.post(`/subscriptions/cancel-renewal`);
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async activateRenewal() {
        try {
            const response = await this.axios.post(`/subscriptions/activate-renewal`);
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async getSubscriptionActive() {
        try {
            const response = await this.axios.get(`/subscriptions/active`);
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async getRateAvailableForFeature(dto) {
        try {
            const response = await this.axios.post(`/subscriptions/feature-rate-available`, dto);
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
}
