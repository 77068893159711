import { parseException } from "../errors";
export class PaymentsController {
    constructor(axios) {
        this.axios = axios;
    }
    async deleteSubscription(id) {
        try {
            const response = await this.axios.delete(`/payments/subscriptions/${id}`);
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async setSubscriptionAsDefault(id) {
        try {
            const response = await this.axios.post(`/payments/subscriptions/${id}/set-as-default`);
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async createOrderSubscription(dto) {
        try {
            const response = await this.axios.post(`/payments/order/subscription`, dto);
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async updateOrderWithClientError(dto) {
        try {
            const response = await this.axios.post(`/payments/order/set-client-error`, dto);
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async getPaymentMethods() {
        try {
            const response = await this.axios.get(`/payments/methods`);
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async getSubscriptions() {
        try {
            const response = await this.axios.get(`/payments/subscriptions`);
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async confirmation(dto) {
        try {
            const response = await this.axios.post(`/payments/confirmation`, dto);
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
}
