export class LanguagesController {
    constructor(axios) {
        this.axios = axios;
    }
    async find() {
        try {
            const response = await this.axios.get("/languages");
            return response.data;
        }
        catch (e) {
            throw e;
        }
    }
}
