var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { IsDate, IsNumber, IsUUID } from "class-validator";
export class CreatePreInvoiceBreakdownItemDto {
    product;
    reference;
    unit_price;
    qty;
    tax_type;
}
export class CreatePreInvoiceDto {
    agency_id;
    issue_date;
    payment_due_date;
    breakdown;
}
export class MarkAsPayedInvoiceDto {
    invoice_id;
    order_id;
    payment_date;
    total_charged;
}
__decorate([
    IsUUID(),
    __metadata("design:type", String)
], MarkAsPayedInvoiceDto.prototype, "invoice_id", void 0);
__decorate([
    IsUUID(),
    __metadata("design:type", String)
], MarkAsPayedInvoiceDto.prototype, "order_id", void 0);
__decorate([
    IsDate(),
    __metadata("design:type", Date)
], MarkAsPayedInvoiceDto.prototype, "payment_date", void 0);
__decorate([
    IsNumber(),
    __metadata("design:type", Number)
], MarkAsPayedInvoiceDto.prototype, "total_charged", void 0);
export class BillingPayInvoiceDto {
    invoice_id;
    payment_subscription_id;
}
__decorate([
    IsUUID(),
    __metadata("design:type", String)
], BillingPayInvoiceDto.prototype, "invoice_id", void 0);
__decorate([
    IsUUID(),
    __metadata("design:type", String)
], BillingPayInvoiceDto.prototype, "payment_subscription_id", void 0);
export class RegenerateInvoicePdfDto {
    invoice_id;
}
__decorate([
    IsUUID(),
    __metadata("design:type", String)
], RegenerateInvoicePdfDto.prototype, "invoice_id", void 0);
