var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { IsInt, IsOptional, IsString } from "class-validator";
import { FindAllRequest } from "./find-all-request";
import { Type } from "class-transformer";
// export class UpdateGeoAddressDto {
//   @IsString()
//   address: string;
//   @IsNumber()
//   latitude: number;
//   @IsNumber()
//   longitude: number;
//   @IsOptional()
//   @IsString()
//   route: string | null;
//   @IsOptional()
//   @IsString()
//   street_number: string | null;
//   @IsOptional()
//   @IsString()
//   postal_code: string | null;
//   @IsOptional()
//   @IsString()
//   locality: string | null;
//   @IsOptional()
//   @IsString()
//   country: string | null;
// }
export class GetZonesQueryDto extends FindAllRequest {
    min_level;
    max_level;
    closest_zone_id;
}
__decorate([
    IsOptional(),
    IsInt(),
    Type(() => Number),
    __metadata("design:type", Number)
], GetZonesQueryDto.prototype, "min_level", void 0);
__decorate([
    IsOptional(),
    IsInt(),
    Type(() => Number),
    __metadata("design:type", Number)
], GetZonesQueryDto.prototype, "max_level", void 0);
__decorate([
    IsOptional(),
    IsString(),
    __metadata("design:type", String)
], GetZonesQueryDto.prototype, "closest_zone_id", void 0);
