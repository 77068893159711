var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { IsNumber, IsString, IsUUID } from "class-validator";
export class CreateAgencyWalletDto {
    agency_id;
    wallet_type_code;
}
__decorate([
    IsUUID(),
    __metadata("design:type", String)
], CreateAgencyWalletDto.prototype, "agency_id", void 0);
__decorate([
    IsString(),
    __metadata("design:type", String)
], CreateAgencyWalletDto.prototype, "wallet_type_code", void 0);
export class CreateAgencyWalletAccountDto {
    agency_wallet_id;
    wallet_account_type_code;
}
__decorate([
    IsUUID(),
    __metadata("design:type", String)
], CreateAgencyWalletAccountDto.prototype, "agency_wallet_id", void 0);
__decorate([
    IsString(),
    __metadata("design:type", String)
], CreateAgencyWalletAccountDto.prototype, "wallet_account_type_code", void 0);
export class AgencyHasFundsDto {
    agency_id;
    wallet_type_code;
    credits_required;
}
__decorate([
    IsUUID(),
    __metadata("design:type", String)
], AgencyHasFundsDto.prototype, "agency_id", void 0);
__decorate([
    IsString(),
    __metadata("design:type", String)
], AgencyHasFundsDto.prototype, "wallet_type_code", void 0);
__decorate([
    IsNumber(),
    __metadata("design:type", Number)
], AgencyHasFundsDto.prototype, "credits_required", void 0);
export class WalletConsumeCreditsDto {
    agency_id;
    wallet_type_code;
    credits_to_consume;
    metadata;
    message;
}
__decorate([
    IsUUID(),
    __metadata("design:type", String)
], WalletConsumeCreditsDto.prototype, "agency_id", void 0);
__decorate([
    IsString(),
    __metadata("design:type", String)
], WalletConsumeCreditsDto.prototype, "wallet_type_code", void 0);
__decorate([
    IsNumber(),
    __metadata("design:type", Number)
], WalletConsumeCreditsDto.prototype, "credits_to_consume", void 0);
