var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { IsOptional, IsString } from "class-validator";
export class UpdateFacebookIntegrationDto {
    page_id;
    business_id;
    catalog_id;
}
__decorate([
    IsOptional(),
    IsString(),
    __metadata("design:type", String)
], UpdateFacebookIntegrationDto.prototype, "page_id", void 0);
__decorate([
    IsOptional(),
    IsString(),
    __metadata("design:type", String)
], UpdateFacebookIntegrationDto.prototype, "business_id", void 0);
__decorate([
    IsOptional(),
    IsString(),
    __metadata("design:type", String)
], UpdateFacebookIntegrationDto.prototype, "catalog_id", void 0);
export class CreateFacebookCatalogDto {
    business_id;
    name;
}
__decorate([
    IsString(),
    __metadata("design:type", String)
], CreateFacebookCatalogDto.prototype, "business_id", void 0);
__decorate([
    IsString(),
    __metadata("design:type", String)
], CreateFacebookCatalogDto.prototype, "name", void 0);
export class GetFacebookCatalogsDto {
    business_id;
}
__decorate([
    IsString(),
    __metadata("design:type", String)
], GetFacebookCatalogsDto.prototype, "business_id", void 0);
