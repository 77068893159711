var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Type } from "class-transformer";
import { IsUUID, IsOptional, IsObject, IsArray, ArrayNotEmpty, ValidateNested, IsString, IsBoolean, } from "class-validator";
export class StartSignFlowDto {
    document_id;
    metadata;
    signers;
    send_notification_to_signers;
}
__decorate([
    IsUUID(),
    __metadata("design:type", String)
], StartSignFlowDto.prototype, "document_id", void 0);
__decorate([
    IsOptional(),
    IsObject(),
    __metadata("design:type", Object)
], StartSignFlowDto.prototype, "metadata", void 0);
__decorate([
    IsArray(),
    ArrayNotEmpty(),
    ValidateNested({ each: true }),
    Type(() => StartSignFlowSignerDto),
    __metadata("design:type", Array)
], StartSignFlowDto.prototype, "signers", void 0);
__decorate([
    IsOptional(),
    IsBoolean(),
    __metadata("design:type", Boolean)
], StartSignFlowDto.prototype, "send_notification_to_signers", void 0);
export class StartSignFlowSignerDto {
    contact_id;
    evidences_expected;
}
__decorate([
    IsUUID(),
    __metadata("design:type", String)
], StartSignFlowSignerDto.prototype, "contact_id", void 0);
__decorate([
    IsArray(),
    ArrayNotEmpty(),
    IsString({ each: true }),
    __metadata("design:type", Array)
], StartSignFlowSignerDto.prototype, "evidences_expected", void 0);
export class GetSignFlowDto {
    agency_id;
    signature_id;
    signer_id;
}
__decorate([
    IsUUID(),
    __metadata("design:type", String)
], GetSignFlowDto.prototype, "agency_id", void 0);
__decorate([
    IsUUID(),
    __metadata("design:type", String)
], GetSignFlowDto.prototype, "signature_id", void 0);
__decorate([
    IsUUID(),
    __metadata("design:type", String)
], GetSignFlowDto.prototype, "signer_id", void 0);
export class SignatureConfirmEvidenceDto {
    agency_id;
    signature_id;
    signer_id;
    evidence_id;
    sign_details;
}
__decorate([
    IsUUID(),
    __metadata("design:type", String)
], SignatureConfirmEvidenceDto.prototype, "agency_id", void 0);
__decorate([
    IsUUID(),
    __metadata("design:type", String)
], SignatureConfirmEvidenceDto.prototype, "signature_id", void 0);
__decorate([
    IsUUID(),
    __metadata("design:type", String)
], SignatureConfirmEvidenceDto.prototype, "signer_id", void 0);
__decorate([
    IsUUID(),
    __metadata("design:type", String)
], SignatureConfirmEvidenceDto.prototype, "evidence_id", void 0);
__decorate([
    IsObject(),
    __metadata("design:type", Object)
], SignatureConfirmEvidenceDto.prototype, "sign_details", void 0);
export class SignatureSendVerificationCodeDto {
    agency_id;
    signature_id;
    signer_id;
    evidence_id;
}
__decorate([
    IsUUID(),
    __metadata("design:type", String)
], SignatureSendVerificationCodeDto.prototype, "agency_id", void 0);
__decorate([
    IsUUID(),
    __metadata("design:type", String)
], SignatureSendVerificationCodeDto.prototype, "signature_id", void 0);
__decorate([
    IsUUID(),
    __metadata("design:type", String)
], SignatureSendVerificationCodeDto.prototype, "signer_id", void 0);
__decorate([
    IsUUID(),
    __metadata("design:type", String)
], SignatureSendVerificationCodeDto.prototype, "evidence_id", void 0);
