var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { IsDate, IsOptional, IsUUID } from "class-validator";
import { FindAllRequest } from "./find-all-request.js";
import { Type } from "class-transformer";
export class DemandsFindAllRequest extends FindAllRequest {
    property_id;
}
__decorate([
    IsOptional(),
    IsUUID(),
    __metadata("design:type", String)
], DemandsFindAllRequest.prototype, "property_id", void 0);
export class DemandsStatisticsDto {
    from_date;
    to_date;
}
__decorate([
    IsDate(),
    Type(() => Date),
    __metadata("design:type", Date)
], DemandsStatisticsDto.prototype, "from_date", void 0);
__decorate([
    IsDate(),
    Type(() => Date),
    __metadata("design:type", Date)
], DemandsStatisticsDto.prototype, "to_date", void 0);
export class DemandsStatisticsItem {
    date;
    new_demands;
}
