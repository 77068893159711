import FormData from "form-data";
export class SignaturesController {
    constructor(axios) {
        this.axios = axios;
    }
    async detail(id) {
        try {
            const response = await this.axios.get(`/signatures/${id}`);
            return response.data;
        }
        catch (e) {
            throw e;
        }
    }
    async findSignatureByDocument(documentId) {
        try {
            const response = await this.axios.get(`/signatures/find-by-document/${documentId}`);
            return response.data;
        }
        catch (e) {
            throw e;
        }
    }
    async startSignFlow(dto) {
        try {
            const response = await this.axios.post("/signatures/start-sign-flow", dto);
            return response.data;
        }
        catch (e) {
            throw e;
        }
    }
    async getSignFlow(dto) {
        try {
            const response = await this.axios.get("/signatures/sign-flow", {
                params: dto,
            });
            return response.data;
        }
        catch (e) {
            throw e;
        }
    }
    async confirmEvidence(dto) {
        try {
            const response = await this.axios.post("/signatures/confirm-evidence", dto);
            return response.data;
        }
        catch (e) {
            throw e;
        }
    }
    async uploadDocumentForEvidence(agencyId, signatureId, signerId, evidenceId, fileName, file) {
        const data = new FormData();
        data.append("file", file, fileName);
        const response = await this.axios.post("/signatures/upload-document-for-evidence", data, {
            params: {
                agency_id: agencyId,
                signature_id: signatureId,
                signer_id: signerId,
                evidence_id: evidenceId,
            },
        });
        return response.data;
    }
    async sendVerificationCode(dto) {
        try {
            await this.axios.post("/signatures/send-verification-code", dto);
        }
        catch (e) {
            throw e;
        }
    }
}
