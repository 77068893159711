var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { parseException } from "../errors";
import UseRecaptcha from "../use-recaptcha";
export class UserController {
    constructor(axios) {
        this.axios = axios;
    }
    async getStatus(userId) {
        try {
            const response = await this.axios.get(`/users/${userId}/status`);
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async impersonate(userId) {
        try {
            const response = await this.axios.post(`/users/${userId}/impersonate`);
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async findAll() {
        try {
            const response = await this.axios.get(`/users/all`);
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async findAllActive() {
        try {
            const response = await this.axios.get(`/users/allActive`);
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async getUserStatuses() {
        try {
            const response = await this.axios.get(`/users/statuses`);
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async getCurrentRole() {
        try {
            const response = await this.axios.get("/users/self/role");
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async patchSelf(dto) {
        try {
            const response = await this.axios.patch("/users/self", dto);
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async updatePassword(dto) {
        try {
            await this.axios.post("/users/self/update-password", dto);
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async resendVerificationEmail() {
        try {
            await this.axios.post("/users/self/resend-verification-email");
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async validateEmail(dto) {
        try {
            await this.axios.post("/users/validate-email", dto);
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async register(request) {
        try {
            const response = await this.axios.post("/users/register", request);
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async recoveryPassword(request) {
        try {
            await this.axios.post("/users/recovery-password", request);
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async resetPassword(request) {
        try {
            const response = await this.axios.post("/users/reset-password", request);
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
}
__decorate([
    UseRecaptcha(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Function]),
    __metadata("design:returntype", Promise)
], UserController.prototype, "validateEmail", null);
__decorate([
    UseRecaptcha(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Function]),
    __metadata("design:returntype", Promise)
], UserController.prototype, "register", null);
__decorate([
    UseRecaptcha(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Function]),
    __metadata("design:returntype", Promise)
], UserController.prototype, "recoveryPassword", null);
__decorate([
    UseRecaptcha(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Function]),
    __metadata("design:returntype", Promise)
], UserController.prototype, "resetPassword", null);
