import { parseException } from "../errors";
export class SerenaController {
    constructor(axios) {
        this.axios = axios;
    }
    async startConversation(channel) {
        try {
            const response = await this.axios.post(`/serena/conversation?channel=${encodeURIComponent(channel)}`);
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async endConversation(chatId) {
        try {
            await this.axios.delete(`/serena/conversation/${chatId}`);
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async getAgencyUserChat(chatId) {
        try {
            const response = await this.axios.get(`/serena/chat/${chatId}`);
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async sendMessage(dto) {
        try {
            const response = await this.axios.post(`/serena/message`, dto);
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
}
