import { parseException } from "../errors";
export class AcquisitionsController {
    constructor(axios) {
        this.axios = axios;
    }
    async getBulkRequests(channelCode) {
        try {
            const response = await this.axios.get("/acquisitions/bulk-requests", { params: { channel: channelCode } });
            return response.data;
        }
        catch (e) {
            throw e;
        }
    }
    async updateBulkSummary(id, dto) {
        try {
            const response = await this.axios.post(`/acquisitions/bulk-requests/${id}/summary`, dto);
            return response.data;
        }
        catch (e) {
            throw e;
        }
    }
    async updateBulkPropertyProgress(id, dto) {
        try {
            const response = await this.axios.post(`/acquisitions/bulk-requests/${id}/property`, dto);
            return response.data;
        }
        catch (e) {
            throw e;
        }
    }
    async internalExists(filter) {
        try {
            const response = await this.axios.get("/acquisitions/internal-exists", {
                params: filter,
            });
            return response.data;
        }
        catch (e) {
            throw e;
        }
    }
    async internalExistsContact(dto) {
        try {
            const response = await this.axios.get("/acquisitions/internal-exists-contact", {
                params: dto,
            });
            return response.data;
        }
        catch (e) {
            throw e;
        }
    }
    async internalFind(filter) {
        try {
            const response = await this.axios.get("/acquisitions/internal-find", {
                params: filter,
            });
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async internalUpsert(dto) {
        try {
            const response = await this.axios.post("/acquisitions/internal-upsert", dto);
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async internalSetPublishedStatus(dto) {
        try {
            const response = await this.axios.post("/acquisitions/internal-set-published-status", dto);
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
}
