import { parseException } from "../errors";
export class FacebookController {
    constructor(axios) {
        this.axios = axios;
    }
    async getOAuthRequest() {
        try {
            const response = await this.axios.get("/facebook/oauth-request");
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async getStatus() {
        try {
            const response = await this.axios.get("/facebook/status");
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async getPages() {
        try {
            const response = await this.axios.get("/facebook/pages");
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async getBusinesses() {
        try {
            const response = await this.axios.get("/facebook/businesses");
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async getCatalogs(businessId) {
        try {
            const response = await this.axios.get("/facebook/catalogs", { params: { business_id: businessId } });
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async createCatalog(dto) {
        try {
            const response = await this.axios.post("/facebook/create-catalog", dto);
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async disconnectIntegration() {
        try {
            const response = await this.axios.post("/facebook/disconnect-integration");
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async updateIntegration(dto) {
        try {
            const response = await this.axios.post("/facebook/update-integration", dto);
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
}
