import { parseException } from "../errors";
export class ChannelsController {
    constructor(axios) {
        this.axios = axios;
    }
    async find(filters) {
        try {
            const response = await this.axios.get("/channels", {
                params: filters,
            });
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
}
