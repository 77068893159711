import { parseException } from "../errors";
export class NotificationsController {
    constructor(axios) {
        this.axios = axios;
    }
    async getNotificationsPendingToRead() {
        try {
            const response = await this.axios.get(`/notifications/pending-to-read`);
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async markAsRead(ids) {
        try {
            const response = await this.axios.post(`/notifications/mark-as-read`, ids);
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async getNotifications() {
        try {
            const response = await this.axios.get(`/notifications`);
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
}
