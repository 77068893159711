import { parseException } from "../errors";
export class NewslettersController {
    constructor(axios) {
        this.axios = axios;
    }
    async getSubscriptions() {
        try {
            const response = await this.axios.get("/newsletters/subscriptions");
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async verify(dto) {
        try {
            const response = await this.axios.post("/newsletters/subscriptions/verify", dto);
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
}
