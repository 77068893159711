import { ServiestateApi } from "./api";
export default function UseRecaptcha(action) {
    return function _WithRecaptcha(target, propertyKey, descriptor) {
        action = action !== null && action !== void 0 ? action : `${target.constructor.name}_${propertyKey}`;
        const value = descriptor.value;
        descriptor.value = async function (...args) {
            const axios = this.axios;
            if (axios) {
                const token = await ServiestateApi.recaptchaTokenResolver(action);
                if (token) {
                    axios.defaults.headers.common["x-recaptcha"] = token;
                    const result = await value.apply(this, args);
                    delete axios.defaults.headers.common["x-recaptcha"];
                    return result;
                }
            }
            return await value.apply(this, args);
        };
    };
}
