export class PromotionsController {
    constructor(axios) {
        this.axios = axios;
    }
    async getApplicable(dto) {
        try {
            const response = await this.axios.post("/promotions/applicable", dto);
            return response.data;
        }
        catch (e) {
            throw e;
        }
    }
}
