import FormData from "form-data";
import { parseException } from "../errors";
export class DocumentsController {
    constructor(axios) {
        this.axios = axios;
    }
    async getSpaceUsed() {
        try {
            const response = await this.axios.get(`/documents/space-used`);
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async detail(id) {
        try {
            const response = await this.axios.get(`/documents/${id}`);
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async download(documentId) {
        try {
            const response = await this.axios.get(`/documents/${documentId}/content`, {
                responseType: "blob",
            });
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async upload(fileName, file, progressCallback) {
        try {
            const data = new FormData();
            data.append("file", file, encodeURIComponent(fileName));
            const response = await this.axios.post("/documents/upload", data, {
                onUploadProgress: (progressEvent) => {
                    progressCallback &&
                        progressCallback((progressEvent.loaded * 100) / progressEvent.total);
                },
            });
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
}
