import { parseException } from "../errors";
export class BillingController {
    constructor(axios) {
        this.axios = axios;
    }
    async getInvoices() {
        try {
            const response = await this.axios.get(`/billing/invoices`);
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async getInvoice(id) {
        try {
            const response = await this.axios.get(`/billing/invoices/${id}`);
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async payInvoice(dto) {
        try {
            const response = await this.axios.post(`/billing/invoices/pay`, dto);
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
}
