var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { IsString, IsOptional, IsInt, IsNumber, IsEnum } from "class-validator";
import { Type } from "class-transformer";
export class OrderedRequest {
    order_by;
}
__decorate([
    IsOptional(),
    IsString(),
    __metadata("design:type", String)
], OrderedRequest.prototype, "order_by", void 0);
export class PaginatedRequest extends OrderedRequest {
    skip;
    take;
}
__decorate([
    IsOptional(),
    IsNumber(),
    IsInt(),
    Type(() => Number),
    __metadata("design:type", Number)
], PaginatedRequest.prototype, "skip", void 0);
__decorate([
    IsOptional(),
    IsNumber(),
    IsInt(),
    Type(() => Number),
    __metadata("design:type", Number)
], PaginatedRequest.prototype, "take", void 0);
export class FindAllRequest extends PaginatedRequest {
    query;
    status;
    expand;
}
__decorate([
    IsOptional(),
    IsString(),
    __metadata("design:type", String)
], FindAllRequest.prototype, "query", void 0);
__decorate([
    IsOptional(),
    IsEnum(["active", "archived", "published"]),
    __metadata("design:type", String)
], FindAllRequest.prototype, "status", void 0);
__decorate([
    IsOptional(),
    IsString({ each: true }),
    __metadata("design:type", Array)
], FindAllRequest.prototype, "expand", void 0);
