var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getResponse } from "./get-response";
import { createError } from "./create-error";
import { processResponse } from "./process-response";
export function handleRequestWithTimeout(request, config) {
    return __awaiter(this, void 0, void 0, function* () {
        let timer = null;
        const timeoutErrorPromise = new Promise((_, reject) => {
            timer = setTimeout(() => {
                const message = config.timeoutErrorMessage
                    ? config.timeoutErrorMessage
                    : `timeout of ${config.timeout}ms exceeded`;
                reject(createError(message, config, "ETIMEDOUT", request));
            }, config.timeout);
        });
        const responsePromise = getResponse(request, config);
        const response = yield Promise.race([responsePromise, timeoutErrorPromise]);
        if (timer)
            clearTimeout(timer);
        return processResponse(response, request, config);
    });
}
