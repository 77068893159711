var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createError } from "./create-error";
export function getResponse(request, config) {
    return __awaiter(this, void 0, void 0, function* () {
        let stageOne;
        try {
            stageOne = yield fetch(request);
        }
        catch (e) {
            return createError("Network Error", config, "ERR_NETWORK", request);
        }
        const headers = Object.assign({}, stageOne.headers);
        const response = {
            status: stageOne.status,
            statusText: stageOne.statusText,
            headers,
            config,
            request,
            data: undefined,
        };
        if (stageOne.status >= 200 && stageOne.status !== 204) {
            switch (config.responseType) {
                case "arraybuffer":
                    response.data = yield stageOne.arrayBuffer();
                    break;
                case "blob":
                    response.data = yield stageOne.blob();
                    break;
                case "json":
                    response.data = yield stageOne.json();
                    break;
                case "stream":
                    response.data = stageOne.body;
                    break;
                default:
                    response.data = yield stageOne.text();
                    break;
            }
        }
        return Object.assign(Object.assign({}, response), { ok: stageOne.ok });
    });
}
