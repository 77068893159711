export class MessagesController {
    constructor(axios) {
        this.axios = axios;
    }
    async getConversations() {
        try {
            const response = await this.axios.get("/conversations");
            return response.data;
        }
        catch (e) {
            throw e;
        }
    }
    async countUnread() {
        try {
            const response = await this.axios.get("/messages/count-unread");
            return response.data;
        }
        catch (e) {
            throw e;
        }
    }
    async findOne(id) {
        try {
            const response = await this.axios.get(`/messages/${id}`);
            return response.data;
        }
        catch (e) {
            throw e;
        }
    }
    async findAll(filter) {
        try {
            const response = await this.axios.get("/messages", {
                params: filter,
            });
            return response.data;
        }
        catch (e) {
            throw e;
        }
    }
    async sendMessage(dto) {
        try {
            const response = await this.axios.post("/messages", dto);
            return response.data;
        }
        catch (e) {
            throw e;
        }
    }
    async markAsRead(contactId) {
        try {
            await this.axios.put("/messages/mark-as-read", {
                contact_id: contactId,
            });
        }
        catch (e) {
            throw e;
        }
    }
}
