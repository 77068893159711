import combineURLs from "axios/unsafe/helpers/combineURLs";
import isAbsoluteURL from "axios/unsafe/helpers/isAbsoluteURL";
export function buildFullPath(requestedURL, baseURL) {
    if (requestedURL && baseURL && !isAbsoluteURL(requestedURL)) {
        return combineURLs(baseURL, requestedURL);
    }
    if (!requestedURL && baseURL) {
        return baseURL;
    }
    return requestedURL;
}
