var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { IsEmail, IsOptional, IsString, IsUUID, Matches, MaxLength, MinLength, } from "class-validator";
export class PatchUserDto {
    fullname;
    avatar_id;
    email;
    mobile;
}
__decorate([
    IsOptional(),
    IsString(),
    __metadata("design:type", String)
], PatchUserDto.prototype, "fullname", void 0);
__decorate([
    IsOptional(),
    IsUUID(),
    __metadata("design:type", String)
], PatchUserDto.prototype, "avatar_id", void 0);
__decorate([
    IsOptional(),
    IsEmail(),
    __metadata("design:type", String)
], PatchUserDto.prototype, "email", void 0);
__decorate([
    IsOptional(),
    IsString(),
    __metadata("design:type", String)
], PatchUserDto.prototype, "mobile", void 0);
export class UpdatePasswordDto {
    current_password;
    new_password;
}
__decorate([
    IsString(),
    __metadata("design:type", String)
], UpdatePasswordDto.prototype, "current_password", void 0);
__decorate([
    IsString(),
    MinLength(6),
    MaxLength(50),
    Matches(/^(?=.*[A-Z])(?=.*\d).*$/),
    __metadata("design:type", String)
], UpdatePasswordDto.prototype, "new_password", void 0);
export class RegisterUserDto {
    fullname;
    email;
    password;
    mobile;
}
__decorate([
    IsString(),
    __metadata("design:type", String)
], RegisterUserDto.prototype, "fullname", void 0);
__decorate([
    IsString(),
    IsEmail(),
    __metadata("design:type", String)
], RegisterUserDto.prototype, "email", void 0);
__decorate([
    IsOptional(),
    IsString(),
    MinLength(6),
    MaxLength(30),
    Matches(/\d/),
    Matches(/[A-Z]/),
    __metadata("design:type", String)
], RegisterUserDto.prototype, "password", void 0);
__decorate([
    IsString(),
    __metadata("design:type", String)
], RegisterUserDto.prototype, "mobile", void 0);
export class RecoveryPasswordUserDto {
    email;
}
__decorate([
    IsEmail(),
    __metadata("design:type", String)
], RecoveryPasswordUserDto.prototype, "email", void 0);
export class ResetPasswordUserDto {
    user_id;
    code;
    new_password;
}
__decorate([
    IsUUID(),
    __metadata("design:type", String)
], ResetPasswordUserDto.prototype, "user_id", void 0);
__decorate([
    IsString(),
    __metadata("design:type", String)
], ResetPasswordUserDto.prototype, "code", void 0);
__decorate([
    IsString(),
    MinLength(6),
    MaxLength(30),
    Matches(/\d/),
    Matches(/[A-Z]/),
    __metadata("design:type", String)
], ResetPasswordUserDto.prototype, "new_password", void 0);
export class ValidateUserDto {
    user_id;
    email;
    code;
    constructor(obj) {
        Object.assign(this, obj);
    }
}
__decorate([
    IsUUID(),
    __metadata("design:type", String)
], ValidateUserDto.prototype, "user_id", void 0);
__decorate([
    IsEmail(),
    __metadata("design:type", String)
], ValidateUserDto.prototype, "email", void 0);
__decorate([
    IsString(),
    __metadata("design:type", String)
], ValidateUserDto.prototype, "code", void 0);
