import { parseException } from "../errors";
export class PollsController {
    constructor(axios) {
        this.axios = axios;
    }
    async find(filter) {
        try {
            const response = await this.axios.get(`/polls`, {
                params: filter,
            });
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async findById(id) {
        try {
            const response = await this.axios.get(`/polls/${id}`);
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async getResults(id) {
        try {
            const response = await this.axios.get(`/polls/${id}/results`);
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async sendPoll(pollId, dto) {
        try {
            const response = await this.axios.post(`/polls/${pollId}/send`, dto);
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async create(dto) {
        try {
            const response = await this.axios.post(`/polls`, dto);
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async update(id, dto) {
        try {
            const response = await this.axios.patch(`/polls/${id}`, dto);
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async submit(id, dto) {
        try {
            await this.axios.post(`/polls/${id}/submit`, dto);
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async publish(id) {
        try {
            await this.axios.post(`/polls/${id}/publish`);
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async archive(id) {
        try {
            await this.axios.post(`/polls/${id}/archive`);
        }
        catch (e) {
            throw parseException(e);
        }
    }
}
