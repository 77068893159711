export class HelpController {
    constructor(axios) {
        this.axios = axios;
    }
    async getRecentArticles() {
        try {
            const response = await this.axios.get("/help/articles/recent");
            return response.data;
        }
        catch (e) {
            throw e;
        }
    }
}
