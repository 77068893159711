var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { FindAllRequest } from "./find-all-request.js";
import { IsArray, IsEnum, IsInt, IsNumber, IsOptional, IsString, IsUUID, ValidateNested, } from "class-validator";
import { Transform, Type } from "class-transformer";
export class PropertySubTypeRequest {
    type_id;
    ids;
}
__decorate([
    IsOptional(),
    IsNumber(),
    __metadata("design:type", Number)
], PropertySubTypeRequest.prototype, "type_id", void 0);
__decorate([
    IsOptional(),
    IsArray(),
    IsNumber({}, { each: true }),
    __metadata("design:type", Array)
], PropertySubTypeRequest.prototype, "ids", void 0);
export class PropertiesFindAllNot {
    ids;
    status;
}
__decorate([
    IsOptional(),
    IsUUID("all", { each: true }),
    __metadata("design:type", Array)
], PropertiesFindAllNot.prototype, "ids", void 0);
__decorate([
    IsOptional(),
    IsEnum(["active", "archived", "published"]),
    __metadata("design:type", String)
], PropertiesFindAllNot.prototype, "status", void 0);
export class PropertiesStatisticsRequest {
    transaction_type_id;
}
__decorate([
    IsOptional(),
    IsInt(),
    __metadata("design:type", Number)
], PropertiesStatisticsRequest.prototype, "transaction_type_id", void 0);
export class PropertiesStatistics {
    total_amount;
    comission_expected;
}
export class PropertyChannelRetryDto {
    property_id;
    channel_code;
}
__decorate([
    IsUUID(),
    __metadata("design:type", String)
], PropertyChannelRetryDto.prototype, "property_id", void 0);
__decorate([
    IsString(),
    __metadata("design:type", String)
], PropertyChannelRetryDto.prototype, "channel_code", void 0);
export class PropertiesFindAllRequest extends FindAllRequest {
    channel_code;
    property_type_id;
    transaction_type_id;
    zone;
    min_price;
    max_price;
    reference;
    property_subtype_id;
    min_bedrooms;
    min_bathrooms;
    min_surface;
    status_id;
    extras;
    conservation;
    geo_zone_id;
    ids;
    not;
}
__decorate([
    IsOptional(),
    IsString(),
    __metadata("design:type", String)
], PropertiesFindAllRequest.prototype, "channel_code", void 0);
__decorate([
    IsOptional(),
    IsInt(),
    Type(() => Number),
    __metadata("design:type", Number)
], PropertiesFindAllRequest.prototype, "property_type_id", void 0);
__decorate([
    IsOptional(),
    IsInt(),
    Type(() => Number),
    __metadata("design:type", Number)
], PropertiesFindAllRequest.prototype, "transaction_type_id", void 0);
__decorate([
    IsOptional(),
    IsString(),
    __metadata("design:type", String)
], PropertiesFindAllRequest.prototype, "zone", void 0);
__decorate([
    IsOptional(),
    IsInt(),
    Type(() => Number),
    __metadata("design:type", Number)
], PropertiesFindAllRequest.prototype, "min_price", void 0);
__decorate([
    IsOptional(),
    IsInt(),
    Type(() => Number),
    __metadata("design:type", Number)
], PropertiesFindAllRequest.prototype, "max_price", void 0);
__decorate([
    IsOptional(),
    IsString(),
    __metadata("design:type", String)
], PropertiesFindAllRequest.prototype, "reference", void 0);
__decorate([
    IsOptional(),
    IsInt(),
    Type(() => Number),
    __metadata("design:type", Number)
], PropertiesFindAllRequest.prototype, "property_subtype_id", void 0);
__decorate([
    IsOptional(),
    IsInt(),
    Type(() => Number),
    __metadata("design:type", Number)
], PropertiesFindAllRequest.prototype, "min_bedrooms", void 0);
__decorate([
    IsOptional(),
    IsInt(),
    Type(() => Number),
    __metadata("design:type", Number)
], PropertiesFindAllRequest.prototype, "min_bathrooms", void 0);
__decorate([
    IsOptional(),
    IsInt(),
    Type(() => Number),
    __metadata("design:type", Number)
], PropertiesFindAllRequest.prototype, "min_surface", void 0);
__decorate([
    IsOptional(),
    IsString(),
    __metadata("design:type", Number)
], PropertiesFindAllRequest.prototype, "status_id", void 0);
__decorate([
    Transform(({ value }) => typeof value === "string"
        ? value.toString().split(",")
        : value instanceof Array
            ? value
            : null),
    __metadata("design:type", Array)
], PropertiesFindAllRequest.prototype, "extras", void 0);
__decorate([
    IsOptional(),
    IsArray(),
    IsString({ each: true }),
    Transform(({ value }) => typeof value === "string"
        ? value.toString().split(",")
        : value instanceof Array
            ? value
            : null),
    __metadata("design:type", Array)
], PropertiesFindAllRequest.prototype, "conservation", void 0);
__decorate([
    IsOptional(),
    IsString(),
    __metadata("design:type", String)
], PropertiesFindAllRequest.prototype, "geo_zone_id", void 0);
__decorate([
    IsOptional(),
    IsString({ each: true }),
    __metadata("design:type", Array)
], PropertiesFindAllRequest.prototype, "ids", void 0);
__decorate([
    IsOptional(),
    ValidateNested(),
    Type(() => PropertiesFindAllNot),
    __metadata("design:type", PropertiesFindAllNot)
], PropertiesFindAllRequest.prototype, "not", void 0);
export class GenerateIntelligentContentPropertyDto {
    property_id;
    language_code;
    highlight;
    mutation;
}
__decorate([
    IsUUID(),
    __metadata("design:type", String)
], GenerateIntelligentContentPropertyDto.prototype, "property_id", void 0);
__decorate([
    IsString(),
    __metadata("design:type", String)
], GenerateIntelligentContentPropertyDto.prototype, "language_code", void 0);
__decorate([
    IsOptional(),
    IsString(),
    __metadata("design:type", String)
], GenerateIntelligentContentPropertyDto.prototype, "highlight", void 0);
__decorate([
    IsOptional(),
    ValidateNested(),
    __metadata("design:type", Object)
], GenerateIntelligentContentPropertyDto.prototype, "mutation", void 0);
export class TranslateContentPropertyDto {
    property_id;
    source_lang;
    target_lang;
    title;
    description;
}
__decorate([
    IsUUID(),
    __metadata("design:type", String)
], TranslateContentPropertyDto.prototype, "property_id", void 0);
__decorate([
    IsString(),
    __metadata("design:type", String)
], TranslateContentPropertyDto.prototype, "source_lang", void 0);
__decorate([
    IsString(),
    __metadata("design:type", String)
], TranslateContentPropertyDto.prototype, "target_lang", void 0);
__decorate([
    IsOptional(),
    IsString(),
    __metadata("design:type", String)
], TranslateContentPropertyDto.prototype, "title", void 0);
__decorate([
    IsOptional(),
    IsString(),
    __metadata("design:type", String)
], TranslateContentPropertyDto.prototype, "description", void 0);
export class FindAllPropertyTransactionTypeDto {
    ids;
}
__decorate([
    IsOptional(),
    IsArray(),
    IsNumber({}, { each: true }),
    __metadata("design:type", Array)
], FindAllPropertyTransactionTypeDto.prototype, "ids", void 0);
export class PropertyZonesInUseItem {
    geo_zone;
    count;
}
