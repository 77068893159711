var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { IsEnum, IsOptional, IsString } from "class-validator";
export class SubscriptionCreateInitialPaymentDto {
    plan_id;
    periodicity;
}
__decorate([
    IsString(),
    __metadata("design:type", String)
], SubscriptionCreateInitialPaymentDto.prototype, "plan_id", void 0);
__decorate([
    IsEnum(["yearly", "monthly"]),
    __metadata("design:type", String)
], SubscriptionCreateInitialPaymentDto.prototype, "periodicity", void 0);
export class GetRateAvailableForFeatureDto {
    feature_type;
    feature_name;
}
__decorate([
    IsString(),
    __metadata("design:type", String)
], GetRateAvailableForFeatureDto.prototype, "feature_type", void 0);
__decorate([
    IsString(),
    __metadata("design:type", String)
], GetRateAvailableForFeatureDto.prototype, "feature_name", void 0);
export class CheckRateLimitDto {
    agency_id;
    feature_type;
    feature_name;
    increase;
}
export class SubscriptionChangePlanDto {
    plan_id;
    periodicity;
    promotion_code;
}
__decorate([
    IsString(),
    __metadata("design:type", String)
], SubscriptionChangePlanDto.prototype, "plan_id", void 0);
__decorate([
    IsEnum(["monthly", "yearly"]),
    __metadata("design:type", String)
], SubscriptionChangePlanDto.prototype, "periodicity", void 0);
__decorate([
    IsOptional(),
    IsString(),
    __metadata("design:type", String)
], SubscriptionChangePlanDto.prototype, "promotion_code", void 0);
export class SubscriptionChangePlanVersionDto {
    plan_version_id;
    periodicity;
}
__decorate([
    IsString(),
    __metadata("design:type", String)
], SubscriptionChangePlanVersionDto.prototype, "plan_version_id", void 0);
__decorate([
    IsEnum(["monthly", "yearly"]),
    __metadata("design:type", String)
], SubscriptionChangePlanVersionDto.prototype, "periodicity", void 0);
