import { Box, LinearProgress } from "@mui/material";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

export default function PageLoading() {
  const [loading, setLoading] = useState(false);
  const router = useRouter();

  useEffect(() => {
    const handleStart = () => setLoading(true);
    const handleComplete = () => setLoading(false);

    router.events.on("routeChangeStart", handleStart);
    router.events.on("routeChangeComplete", handleComplete);
    router.events.on("routeChangeError", handleComplete);

    return () => {
      router.events.off("routeChangeStart", handleStart);
      router.events.off("routeChangeComplete", handleComplete);
      router.events.off("routeChangeError", handleComplete);
    };
  }, [router]);

  return (
    <Box
      sx={{
        position: "fixed",
        top: loading ? 0 : -4,
        width: "100vw",
        zIndex: 1000,
        transition: (theme) =>
          theme.transitions.create("top", { duration: "0.25s" }),
      }}
    >
      <LinearProgress color="primary" />
    </Box>
  );
}
