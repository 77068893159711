var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { IsBase64, IsObject, IsString } from "class-validator";
export class CreatePaymentOrderBreakdownItemDto {
    concept;
    qty;
    unit_price;
    tax_type;
}
export class CreatePaymentOrderDto {
    agency_id;
    user_id;
    payment_method_code;
    external_action;
    external_reference;
    breakdown;
}
export class MakeSubscriptionPaymentOrderDto {
    agency_id;
    payment_subscription_id;
    external_action;
    external_reference;
    breakdown;
}
export class CreatePaymentRequestDto {
    agency_id;
    subscription;
    reference;
    net_total;
    tax_total;
    total;
    breakdown;
    settings;
    url_ok;
    url_ko;
}
export class CreatePaymentSubscriptionOrderDto {
    settings;
    agency_id;
    reference;
}
export class MakeSubscriptionPaymentDto {
    agency_id;
    subscription;
    reference;
    net_total;
    tax_total;
    total;
    breakdown;
    settings;
}
export class PaymentConfirmationDto {
    reference;
    params;
}
__decorate([
    IsString(),
    __metadata("design:type", String)
], PaymentConfirmationDto.prototype, "reference", void 0);
__decorate([
    IsObject(),
    __metadata("design:type", Object)
], PaymentConfirmationDto.prototype, "params", void 0);
export class CreateOrderSubscriptionDto {
    payment_method_code;
}
__decorate([
    IsString(),
    __metadata("design:type", String)
], CreateOrderSubscriptionDto.prototype, "payment_method_code", void 0);
export class UpdateOrderWithClientErrorDto {
    order_reference;
    error;
}
__decorate([
    IsString(),
    __metadata("design:type", String)
], UpdateOrderWithClientErrorDto.prototype, "order_reference", void 0);
__decorate([
    IsString(),
    __metadata("design:type", String)
], UpdateOrderWithClientErrorDto.prototype, "error", void 0);
export class RedsysPaymentConfirmationDto {
    Ds_SignatureVersion;
    Ds_MerchantParameters;
    Ds_Signature;
}
__decorate([
    IsString(),
    __metadata("design:type", String)
], RedsysPaymentConfirmationDto.prototype, "Ds_SignatureVersion", void 0);
__decorate([
    IsBase64(),
    __metadata("design:type", String)
], RedsysPaymentConfirmationDto.prototype, "Ds_MerchantParameters", void 0);
__decorate([
    IsString(),
    __metadata("design:type", String)
], RedsysPaymentConfirmationDto.prototype, "Ds_Signature", void 0);
export class ValidationPaymentDto {
    provider_reference;
    settings;
    reference;
    params;
}
__decorate([
    IsString(),
    __metadata("design:type", String)
], ValidationPaymentDto.prototype, "provider_reference", void 0);
__decorate([
    IsObject(),
    __metadata("design:type", Object)
], ValidationPaymentDto.prototype, "settings", void 0);
__decorate([
    IsString(),
    __metadata("design:type", String)
], ValidationPaymentDto.prototype, "reference", void 0);
__decorate([
    IsObject(),
    __metadata("design:type", Object)
], ValidationPaymentDto.prototype, "params", void 0);
export class DeletePaymentSubscriptionDto {
    agency_id;
    subscription;
    settings;
}
__decorate([
    IsString(),
    __metadata("design:type", String)
], DeletePaymentSubscriptionDto.prototype, "agency_id", void 0);
__decorate([
    IsObject(),
    __metadata("design:type", Object)
], DeletePaymentSubscriptionDto.prototype, "subscription", void 0);
__decorate([
    IsObject(),
    __metadata("design:type", Object)
], DeletePaymentSubscriptionDto.prototype, "settings", void 0);
export class RevolutWebhookPayload {
    event;
    order_id;
    merchant_order_ext_ref;
}
__decorate([
    IsString(),
    __metadata("design:type", String)
], RevolutWebhookPayload.prototype, "event", void 0);
__decorate([
    IsString(),
    __metadata("design:type", String)
], RevolutWebhookPayload.prototype, "order_id", void 0);
__decorate([
    IsString(),
    __metadata("design:type", String)
], RevolutWebhookPayload.prototype, "merchant_order_ext_ref", void 0);
