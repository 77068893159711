import type { AppContext, AppProps } from "next/app";
import sdk from "../sdk";
import type { AgencyWebsiteProfile } from "@serviestate/shared";
import Footer from "../components/footer";
import Header from "../components/header";
import Providers from "../providers";
import { getAgencyIdFromRequest } from "../utils";
import "./globals.css";
import { Box, styled } from "@mui/material";
import { appWithTranslation } from "next-i18next";
import PageLoading from "../components/page-loading";
import { BannerWebsiteDemo } from "../components/banner-website-demo";
import Head from "next/head";

function App({
  Component,
  pageProps,
  agency,
}: AppProps & { agency: AgencyWebsiteProfile }) {
  return (
    <Providers agency={agency}>
      <Head>
        <link
          rel="icon"
          type="image/png"
          href={
            agency?.logotype_square_document_id
              ? `/api/documents/${agency.logotype_square_document_id}?width=64&height=64`
              : "/favicon.ico"
          }
        />
      </Head>
      <PageLoading />
      <Box
        sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
      >
        <Header />
        <MainContent>
          <Component {...pageProps} />
        </MainContent>
        <Footer />
        {agency?.demo_mode && <BannerWebsiteDemo />}
      </Box>
    </Providers>
  );
}

const MainContent = styled("main")({
  flex: 1,
  display: "flex",
  flexDirection: "column",
});

async function getAgencyInfo(agencyId: string) {
  if (!agencyId) return null;
  const profile = await sdk.website.getProfile({ agency_id: agencyId });
  if (!profile) return null;
  return profile;
}

App.getInitialProps = async (app: AppContext) => {
  const agencyId = getAgencyIdFromRequest(app.ctx.req);
  const agency = await getAgencyInfo(agencyId);
  return {
    agency,
  };
};

export default appWithTranslation(App);
