import { parseException } from "../errors";
export class AcquisitionsAlertController {
    constructor(axios) {
        this.axios = axios;
    }
    async find(filter) {
        try {
            const response = await this.axios.get("/agency-acquisitions-alert", {
                params: filter,
            });
            return response.data;
        }
        catch (e) {
            throw e;
        }
    }
    async count(filter) {
        try {
            const response = await this.axios.get("/agency-acquisitions-alert/count", {
                params: filter,
            });
            return response.data;
        }
        catch (e) {
            throw e;
        }
    }
    async detail(id) {
        try {
            const response = await this.axios.get(`/agency-acquisitions-alert/${id}`);
            return response.data;
        }
        catch (e) {
            throw e;
        }
    }
    async create(data) {
        try {
            const response = await this.axios.post("/agency-acquisitions-alert", data);
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async update(id, data) {
        try {
            const response = await this.axios.patch(`/agency-acquisitions-alert/${id}`, data);
            return response.data;
        }
        catch (e) {
            throw e;
        }
    }
    async delete(id) {
        try {
            const response = await this.axios.delete(`/agency-acquisitions-alert/${id}`);
            return response.data;
        }
        catch (e) {
            throw e;
        }
    }
}
