import { parseException } from "../errors";
export class DomainsController {
    constructor(axios) {
        this.axios = axios;
    }
    async updateCertificateStatus(dto) {
        try {
            const response = await this.axios.post(`/domains/update-certificate-status`, dto);
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async getDomainsToManage() {
        try {
            const response = await this.axios.get(`/domains/to-manage`);
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async find() {
        try {
            const response = await this.axios.get(`/domains`);
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async register(dto) {
        try {
            const response = await this.axios.post(`/domains/register`, dto);
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async verify(dto) {
        try {
            const response = await this.axios.post(`/domains/verify`, dto);
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async delete(domain) {
        try {
            const params = new URLSearchParams({ domain });
            const response = await this.axios.delete(`/domains?${params.toString()}`);
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
}
