import { parseException } from "../errors";
export class CatastralController {
    constructor(axios) {
        this.axios = axios;
    }
    async findProperty(ref) {
        try {
            const response = await this.axios.get(`/catastral/properties/${ref}`);
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
}
